import {
  faArrowUpRightFromSquare,
  faDownload,
  faFolderOpen,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Button, Card, Col, ListGroup, Row } from "react-bootstrap";
import { getUserDetails } from "../redux/Actions/userAction";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

const Documents = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.userDetails);
  const handleDownload = async (link) => {
    if (!link) {
      alert("This image has not been added yet");
      return;
    }
    window.location.href = link;
  };
  const handleOpenLink = (link) => {
    window.open(link, "_blank");
  };
  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  const styles = {
    shadow: {
      boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  };

  return (
    <>
      {user ? (
        <Row className="m-0 p-4">
          <Col>
            <h4 className="text-center text-primary fw-bold">
              Download QR codes
            </h4>
            {/* <hr /> */}
            <Row className="my-5 justify-content-evenly">
              {user.services && user.services.maids == true ? (
                <Col md={3} className="my-2">
                  <Card className="rounded-5 border-0" style={styles.shadow}>
                    <Card.Body className="p-4">
                      <Row>
                        <FontAwesomeIcon
                          className="text-primary p-0"
                          size="4x"
                          icon={faQrcode}
                        />
                      </Row>
                      <h4 className="text-center my-3">For Attendants</h4>
                      <Row className="justify-content-center">
                        <Col xs="auto">
                          <Button
                            variant="outline-success"
                            className="border-0"
                            onClick={() =>
                              handleDownload(user.qrCodes.forMaids)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faDownload}
                              className="fs-4"
                            />
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              ) : (
                <></>
              )}
              {user.services && user.services.valets == true ? (
                <Col md={3} className="my-2">
                  <Card className="rounded-5 border-0" style={styles.shadow}>
                    <Card.Body className="p-4">
                      <Row>
                        <FontAwesomeIcon
                          className="text-primary p-0"
                          size="4x"
                          icon={faQrcode}
                        />
                      </Row>
                      <h4 className="text-center my-3">For Valets</h4>
                      <Row className="justify-content-center">
                        <Col xs="auto">
                          <Button
                            variant="outline-success"
                            className="border-0"
                            onClick={() =>
                              handleDownload(user.qrCodes.forValets)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faDownload}
                              className="fs-4"
                            />
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              ) : (
                <></>
              )}
              {user.services && user.services.bellmen == true ? (
                <Col md={3} className="my-2">
                  <Card className="rounded-5 border-0" style={styles.shadow}>
                    <Card.Body className="p-4">
                      <Row>
                        <FontAwesomeIcon
                          className="text-primary p-0"
                          size="4x"
                          icon={faQrcode}
                        />
                      </Row>
                      <h4 className="text-center my-3">For Bellmen</h4>
                      <Row className="justify-content-center">
                        <Col xs="auto">
                          <Button
                            variant="outline-success"
                            className="border-0"
                            onClick={() =>
                              handleDownload(user.qrCodes.forBellmen)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faDownload}
                              className="fs-4"
                            />
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              ) : (
                <></>
              )}
              {user.services &&
              user.services.valets == true &&
              user.valetLocations.length > 0 ? (
                <>
                  {user.valetLocations.map((location, index) => (
                    <Col md={3} key={index} className="my-2">
                      <Card
                        className="rounded-5 border-0"
                        style={styles.shadow}
                      >
                        <Card.Body className="p-4">
                          <Row>
                            <FontAwesomeIcon
                              className="text-primary p-0"
                              size="4x"
                              icon={faQrcode}
                            />
                          </Row>
                          <h4 className="text-center my-3">
                            {location.locationName}
                          </h4>
                          <Row className="justify-content-center">
                            <Col xs="auto">
                              <Button
                                variant="outline-success"
                                className="border-0"
                                onClick={() => handleDownload(location.qrCode)}
                              >
                                <FontAwesomeIcon
                                  icon={faDownload}
                                  className="fs-4"
                                />
                              </Button>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </>
              ) : (
                <></>
              )}
            </Row>
            <h4 className="text-center text-primary fw-bold">
              Download helping documents
            </h4>
            <Row className="my-5">
              <Col>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <Row className="align-items-center">
                      <Col xs="auto">
                        <FontAwesomeIcon
                          icon={faFolderOpen}
                          className="fs-5 text-primary"
                        />
                      </Col>
                      <Col md={6}>How to onboard your hotel</Col>
                      <Col xs="auto" className="ms-auto">
                        <Button
                          onClick={() => {
                            handleDownload(
                              "https://drive.google.com/file/d/1kmu9xr72avBlzbFwBMeqE817WHWSrPqI/view?usp=sharing"
                            );
                          }}
                          variant="outline-success"
                          className="rounded-4 border-0 fs-5"
                        >
                          <FontAwesomeIcon icon={faDownload} className="" />
                        </Button>

                        <Button
                          variant="outline-primary"
                          className="rounded-4 border-0 fs-5"
                          onClick={() => {
                            handleOpenLink(
                              "https://drive.google.com/file/d/1kmu9xr72avBlzbFwBMeqE817WHWSrPqI/view?usp=sharing"
                            );
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faArrowUpRightFromSquare}
                            className=""
                          />
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <hr className="m-0 p-0" />
                  <ListGroup.Item>
                    <Row className="align-items-center">
                      <Col xs="auto">
                        <FontAwesomeIcon
                          icon={faFolderOpen}
                          className="fs-5 text-primary"
                        />
                      </Col>
                      <Col md={6}>How to onboard your team</Col>
                      <Col xs="auto" className="ms-auto">
                        <Button
                          variant="outline-success"
                          className="rounded-4 border-0 fs-5"
                          onClick={() => {
                            handleDownload(
                              "https://drive.google.com/file/d/1dhIi2EX31bfuOUgysv7jO9rbpziYJCQ9/view?usp=drive_link"
                            );
                          }}
                        >
                          <FontAwesomeIcon icon={faDownload} className="" />
                        </Button>
                        <Button
                          variant="outline-primary"
                          className="rounded-4 border-0 fs-5"
                          onClick={() => {
                            handleOpenLink(
                              "https://drive.google.com/file/d/1dhIi2EX31bfuOUgysv7jO9rbpziYJCQ9/view?usp=drive_link"
                            );
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faArrowUpRightFromSquare}
                            className=""
                          />
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <hr className="m-0 p-0" />
                  {user.services && user.services.maids == true && (
                    <>
                      <ListGroup.Item>
                        <Row className="align-items-center">
                          <Col xs="auto">
                            <FontAwesomeIcon
                              icon={faFolderOpen}
                              className="fs-5 text-primary"
                            />
                          </Col>
                          <Col md={6}>Starter guide for room attendants</Col>
                          <Col xs="auto" className="ms-auto">
                            <Button
                              variant="outline-success"
                              className="rounded-4 border-0 fs-5"
                              onClick={() => {
                                handleDownload(
                                  "https://drive.google.com/file/d/1a_H9Fc6RJC3fzHo1xZFgb5adK9QeNJEf/view?usp=sharing"
                                );
                              }}
                            >
                              <FontAwesomeIcon icon={faDownload} className="" />
                            </Button>
                            <Button
                              variant="outline-primary"
                              className="rounded-4 border-0 fs-5"
                              onClick={() => {
                                handleOpenLink(
                                  "https://drive.google.com/file/d/1a_H9Fc6RJC3fzHo1xZFgb5adK9QeNJEf/view?usp=drive_link"
                                );
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faArrowUpRightFromSquare}
                                className=""
                              />
                            </Button>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      <hr className="m-0 p-0" />
                    </>
                  )}
                  {user.services && user.services.valets == true && (
                    <>
                      <ListGroup.Item>
                        <Row className="align-items-center">
                          <Col xs="auto">
                            <FontAwesomeIcon
                              icon={faFolderOpen}
                              className="fs-5 text-primary"
                            />
                          </Col>
                          <Col md={6}>Starter guide for valets</Col>
                          <Col xs="auto" className="ms-auto">
                            <Button
                              variant="outline-success"
                              className="rounded-4 border-0 fs-5"
                              onClick={() => {
                                handleDownload(
                                  "https://drive.google.com/file/d/1LxQr4ySCIVMppyeelDJXyeaP_3YFlKnP/view?usp=drive_link"
                                );
                              }}
                            >
                              <FontAwesomeIcon icon={faDownload} className="" />
                            </Button>
                            <Button
                              variant="outline-primary"
                              className="rounded-4 border-0 fs-5"
                              onClick={() => {
                                handleOpenLink(
                                  "https://drive.google.com/file/d/1LxQr4ySCIVMppyeelDJXyeaP_3YFlKnP/view?usp=drive_linkk"
                                );
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faArrowUpRightFromSquare}
                                className=""
                              />
                            </Button>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      <hr className="m-0 p-0" />
                    </>
                  )}
                  {user.services && user.services.bellmen == true && (
                    <>
                      <ListGroup.Item>
                        <Row className="align-items-center">
                          <Col xs="auto">
                            <FontAwesomeIcon
                              icon={faFolderOpen}
                              className="fs-5 text-primary"
                            />
                          </Col>
                          <Col md={6}>Starter guide for bellmen</Col>
                          <Col xs="auto" className="ms-auto">
                            <Button
                              variant="outline-success"
                              className="rounded-4 border-0 fs-5"
                              onClick={() => {
                                handleDownload(
                                  "https://drive.google.com/file/d/1OIqKqbkwNAyrMkeobjmz6-fElhGI_b4u/view?usp=drive_link"
                                );
                              }}
                            >
                              <FontAwesomeIcon icon={faDownload} className="" />
                            </Button>
                            <Button
                              variant="outline-primary"
                              className="rounded-4 border-0 fs-5"
                              onClick={() => {
                                handleOpenLink(
                                  "https://drive.google.com/file/d/1OIqKqbkwNAyrMkeobjmz6-fElhGI_b4u/view?usp=drive_link"
                                );
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faArrowUpRightFromSquare}
                                className=""
                              />
                            </Button>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      <hr className="m-0 p-0" />
                    </>
                  )}
                  {/* <ListGroup.Item>
                    <Row className="align-items-center">
                      <Col xs="auto">
                        <FontAwesomeIcon
                          icon={faFolderOpen}
                          className="fs-5 text-primary"
                        />
                      </Col>
                      <Col md={6}>Get the folder with all documents</Col>
                      <Col xs="auto" className="ms-auto">
                        <Button
                          variant="outline-primary"
                          className="rounded-4 border-0 fs-5"
                          onClick={() => {
                            handleOpenLink(
                              "https://drive.google.com/drive/folders/1uOT-_TtlVuQkSYjR83HopWHQqqv1c4oQ?usp=sharing"
                            );
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faArrowUpRightFromSquare}
                            className=""
                          />
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item> */}
                </ListGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </>
  );
};

export default Documents;
