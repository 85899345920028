import React from "react";
import { Circle } from "rc-progress";
import CountUp from "react-countup";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";

const MaidsCard = ({ staff }) => {
  const getInactiveStaff = () => {
    return staff.filter((member) => !member.chargesStatus);
  };

  const totalStaff = staff.length;
  const activeStaff = totalStaff - getInactiveStaff().length;
  const inactiveStaff = getInactiveStaff().length;
  const activePercentage =
    totalStaff > 0 ? Math.round((activeStaff / totalStaff) * 100) : 0;
  const roundProgressColor =
    activePercentage <= 50
      ? "#FFA015"
      : activePercentage >= 50
      ? "#06BF9C"
      : "#3498DB";

  return (
    <>
      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <FontAwesomeIcon
            icon={faUserTie}
            className="text-success fs-2 p-0 ps-2"
          />
        </Col>
        <Col xs="auto" className="round-progress">
          <Circle
            percent={activePercentage}
            strokeWidth={10}
            trailWidth={8}
            strokeColor={roundProgressColor}
          />
          <span
            className={
              activePercentage < 50 ? "text-warning ps-1" : "text-success ps-1"
            }
          >
            {activePercentage}%
          </span>
        </Col>
      </Row>
      <Row className="my-3">
        <Col>
          <Row className="mt-3">
            <h5 className="fw-bold text-primary m-0">Staff members</h5>
          </Row>
          <Row className="">
            {staff.length <= 0 ? (
              <span className="fs-6 text-muted fst-italic text-center my-1">
                Fetching data from stripe...
              </span>
            ) : (
              <h4 className="fw-bolder text-info m-0">
                <CountUp start={0} end={totalStaff} delay={1} />
              </h4>
            )}
          </Row>
        </Col>
      </Row>

      <Row className="justify-content-between my-1">
        <Col xs="auto" className="text-muted">
          Active
        </Col>
        <Col xs="auto" className="text-success">
          <CountUp start={0} end={activeStaff} delay={2} />
        </Col>
      </Row>
      <Row className="justify-content-between my-1">
        <Col xs="auto" className="text-muted">
          Inactive
        </Col>
        <Col xs="auto" className="text-warning">
          <CountUp start={0} end={inactiveStaff} delay={2} />
        </Col>
      </Row>
    </>
  );
};

export default MaidsCard;
