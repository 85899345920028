import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPdf from "jspdf";
import * as XLSX from "xlsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { dataURItoBlob, saveAs } from "../controllers/download";

const ReportDownload = () => {
  const [downloading, setDownloading] = useState(false);
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  const [selectedMaid, setSelectedMaid] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [maids, setMaids] = useState([]);

  const calculateMaidStats = (maid) => {
    const totalEarnings = maid.tips.reduce((acc, tip) => acc + tip.amount, 0);
    const totalReviews = maid.tips.reduce(
      (acc, tip) => acc + tip.review.length,
      0
    );
    return { totalEarnings, totalReviews };
  };
  const handleDownload = () => {
    setDownloading(true);
    const table = document.querySelector(".report");
    const data = [];
    const headers = [];
    table.querySelectorAll("thead th").forEach((th) => {
      headers.push(th.textContent.trim());
    });
    data.push(headers);
    table.querySelectorAll("tbody tr").forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell) => {
        rowData.push(cell.textContent.trim());
      });
      data.push(rowData);
    });
    // Create a worksheet and add the data
    const ws = XLSX.utils.aoa_to_sheet(data);
    // Create a workbook and add the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    // Save the Excel file
    XLSX.writeFile(wb, "report.xlsx");
    setDownloading(false);
  };
  useEffect(() => {
    const maids = JSON.parse(localStorage.getItem("maids"));
    const selectedMaid = JSON.parse(localStorage.getItem("selectedMaid"));
    const startDate = localStorage.getItem("startDate");
    const endDate = localStorage.getItem("endDate");
    setMaids(maids);
    setSelectedMaid(selectedMaid);
    setStartDate(startDate);
    setEndDate(endDate);
    // Clear local storage when tab is closed
    const handleTabClose = () => {
      localStorage.removeItem("selectedMaid");
      localStorage.removeItem("startDate");
      localStorage.removeItem("endDate");
      localStorage.removeItem("maids");
    };

    document.addEventListener("visibilitychange", handleTabClose);

    return () => {
      document.removeEventListener("visibilitychange", handleTabClose);
    };
  }, []);
  return (
    <>
      <Row className="m-0 p-5">
        <Row>
          <Col sm="auto" className="ms-auto">
            <Button variant="success" className="mx-2" onClick={handleDownload}>
              <FontAwesomeIcon className="pe-2" icon={faDownload} />
              {downloading ? "downloading.." : "Download"}
            </Button>
          </Col>
        </Row>
        <Row className="my-5 report">
          {selectedMaid ? (
            <>
              <h5 className="text-center">
                {selectedMaid.firstName}'s Earning
              </h5>
              {startDate != (undefined || "") &&
              endDate != (undefined || "") ? (
                <>
                  <span className="text-muted text-center mb-2">
                    From: <span className="mx-2">{startDate}</span> to:
                    <span className=" mx-2">{endDate}</span>
                  </span>
                </>
              ) : (
                <></>
              )}
              <Table bordered hover className="">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Transaction Id</th>
                    <th>Account Id</th>
                    <th>Tip</th>
                    <th>Reviews</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedMaid &&
                    selectedMaid.tips.map((tip, index) => (
                      <tr key={tip._id}>
                        <td>
                          {new Date(tip.date).toLocaleDateString(
                            "en-US",
                            options
                          )}
                        </td>
                        <td>{tip.paymentId}</td>
                        <td>{tip.account}</td>
                        <td>${tip.amount}</td>
                        <td>{tip.review}</td>
                      </tr>
                    ))}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="text-start">
                      Total = {""}
                      <b>
                        $
                        {selectedMaid.tips.reduce(
                          (acc, tip) => acc + tip.amount,
                          0
                        )}
                      </b>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </>
          ) : (
            <Table bordered hover className="">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Service Name</th>
                  <th>Earnings</th>
                  <th>Reviews</th>
                </tr>
              </thead>
              <tbody>
                {maids &&
                  maids.length > 0 &&
                  maids.map((maid, index) => (
                    <tr key={maid._id}>
                      <td>{index + 1}</td>
                      <td>{`${maid.firstName} ${maid.lastName}`}</td>
                      <td>{maid.serviceName}</td>
                      <td>${calculateMaidStats(maid).totalEarnings}</td>
                      <td>{calculateMaidStats(maid).totalReviews}</td>
                    </tr>
                  ))}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="text-start">
                    Total = {""}
                    <b>
                      $
                      {maids.reduce(
                        (acc, maid) =>
                          acc + calculateMaidStats(maid).totalEarnings,
                        0
                      )}
                    </b>
                  </td>
                </tr>
              </tbody>
            </Table>
          )}
        </Row>
      </Row>
    </>
  );
};

export default ReportDownload;
