import {
  faBars,
  faCircleUser,
  faCubes,
  faHandshakeAngle,
  faMoneyBillTransfer,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, ListGroup, Row, Tab } from "react-bootstrap";
import AdminDashboard from "./AdminDashboard";
import AdminProfilePage from "./AdminProfilePage";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, userLogout } from "../redux/Actions/userAction";
import { setActiveTab } from "../redux/Actions/tabAcions";
import BizDev from "./BizDev";
import TransactionReports from "./TransactionReports";
import BizDevReports from "./BizDevRoperts";
import SuccessfullPaymentsNew from "./SuccessfullPyamentsNew";
import ViewClient from "./ViewClient";

const SuperAdmin = () => {
  const [props, setProps] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const activeTab = useSelector((state) => state.navigation.activeTab);
  const userInfo = useSelector((state) => state.userDetails);
  const { user } = userInfo;

  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(userLogout());
    window.location.href = "/login";
  };

  const handleTabChange = (tabKey, propsValue = null) => {
    setProps(propsValue);
    dispatch(setActiveTab(tabKey));
  };

  useEffect(() => {
    if (!user) {
      dispatch(getUserDetails());
    }
  }, [dispatch]);

  return (
    <>
      <Tab.Container activeKey={activeTab}>
        <Row className="m-0">
          <Col
            sm={collapsed ? 1 : 2}
            className={`sidebar vh-100 bg-primary sticky-top ${
              collapsed ? "collapsed" : "expanded"
            }`}
          >
            <Row className="h-100">
              <ListGroup className="py-5 p-0 " variant="flush">
                <Row className="mb-5 pb-5">
                  <Col className={`${collapsed ? "px-3" : "px-4"}`}>
                    <Button
                      className=" fs-5"
                      onClick={() => setCollapsed(!collapsed)}
                    >
                      <FontAwesomeIcon icon={faBars} />
                    </Button>
                  </Col>
                </Row>

                <ListGroup.Item
                  className={`bg-primary fw-normal fs-5 ${
                    collapsed ? "text-center" : ""
                  }`}
                  action
                  variant="light"
                  href="#dashboard"
                  onClick={() => handleTabChange("#dashboard")}
                >
                  <Row className="justify-content-evenly">
                    <Col xs="auto">
                      <FontAwesomeIcon icon={faCubes} />
                    </Col>
                    {!collapsed && <Col xs={8}> Dashboard</Col>}
                  </Row>
                </ListGroup.Item>

                {user && user.isSuperAdmin && (
                  <>
                    <ListGroup.Item
                      className={`bg-primary fw-normal fs-5 ${
                        collapsed ? "text-center" : ""
                      }`}
                      action
                      variant="light"
                      href="#devpartners"
                      onClick={() => handleTabChange("#devpartners")}
                    >
                      <Row className="justify-content-evenly">
                        <Col xs="auto">
                          <FontAwesomeIcon icon={faHandshakeAngle} />
                        </Col>
                        {!collapsed && (
                          <Col xs={8} className="text-start">
                            Dev Partners
                          </Col>
                        )}
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item
                      className={`bg-primary fw-normal fs-5 ${
                        collapsed ? "text-center" : ""
                      }`}
                      action
                      variant="light"
                      href="#transactions"
                      onClick={() => handleTabChange("#transactions")}
                    >
                      <Row className="justify-content-evenly">
                        <Col xs="auto">
                          <FontAwesomeIcon icon={faMoneyBillTransfer} />
                        </Col>
                        {!collapsed && (
                          <Col xs={8} className="text-start">
                            Reports
                          </Col>
                        )}
                      </Row>
                    </ListGroup.Item>
                  </>
                )}

                <ListGroup.Item
                  className={`bg-primary fw-normal fs-5 ${
                    collapsed ? "text-center" : ""
                  }`}
                  action
                  variant="light"
                  href="#profilePage"
                  onClick={() => handleTabChange("#profilePage")}
                >
                  <Row className="justify-content-evenly">
                    <Col xs="auto">
                      <FontAwesomeIcon icon={faCircleUser} className="fs-4" />
                    </Col>
                    {!collapsed && (
                      <Col xs={8} className="text-start">
                        Profile
                      </Col>
                    )}
                  </Row>
                </ListGroup.Item>

                {user && user.isPartner && (
                  <ListGroup.Item
                    className={`bg-primary fw-normal fs-5 ${
                      collapsed ? "text-center" : ""
                    }`}
                    action
                    variant="light"
                    href="#reports"
                    onClick={() => handleTabChange("#reports")}
                  >
                    <Row className="justify-content-evenly">
                      <Col xs={2}>
                        <FontAwesomeIcon icon={faMoneyBillTransfer} />
                      </Col>
                      {!collapsed && (
                        <Col xs={8} className="text-start">
                          Partner Reports
                        </Col>
                      )}
                    </Row>
                  </ListGroup.Item>
                )}
              </ListGroup>

              <Col
                className="align-self-end text-center fs-4 text-light fw-3 mb-3"
                xs={collapsed ? "auto" : 12}
              >
                <hr />
                <Row className="">
                  <Button size="lg" onClick={handleLogout}>
                    <FontAwesomeIcon
                      className="me-2"
                      icon={faRightFromBracket}
                    />
                    {!collapsed && "Logout"}
                  </Button>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col sm={collapsed ? 11 : 10} className="p-0 mx-auto">
            <Tab.Content>
              <Tab.Pane eventKey="#dashboard">
                <AdminDashboard onTabChange={handleTabChange} />
              </Tab.Pane>
              <Tab.Pane eventKey="#profilePage">
                <AdminProfilePage />
              </Tab.Pane>
              <Tab.Pane eventKey="#devpartners">
                <BizDev />
              </Tab.Pane>
              <Tab.Pane eventKey="#transactions">
                <TransactionReports />
              </Tab.Pane>
              <Tab.Pane eventKey="#transactionsNew">
                <SuccessfullPaymentsNew />
              </Tab.Pane>
              {user && user.isPartner && (
                <Tab.Pane eventKey="#reports">
                  <BizDevReports partner={user} />
                </Tab.Pane>
              )}
              <Tab.Pane eventKey="#viewClient">
                <ViewClient client={props} />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
};

export default SuperAdmin;
