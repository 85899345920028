import React, { useEffect, useState } from "react";

const NewLandingPage = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 500); // Start delay for effect
  }, []);

  return (
    <div className="landing">
      <div className="brand-container">
        <span className={`letter ${isVisible ? "animate" : ""}`}>T</span>
        <span className={`letter ${isVisible ? "animate" : ""}`}>i</span>
        <span className={`letter ${isVisible ? "animate" : ""}`}>p</span>
        <span className={`letter ${isVisible ? "animate" : ""}`}>k</span>
        <span className={`letter ${isVisible ? "animate" : ""}`}>i</span>
        <span className={`letter ${isVisible ? "animate" : ""}`}>t</span>
      </div>
    </div>
  );
};

export default NewLandingPage;
