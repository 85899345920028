import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Button, Col, Image, Row } from "react-bootstrap";
import screenshot from "../images/tipmaid-dashboard-screenshot.png";
import walletIcon from "../images/wallet.png";
import sheildIcon from "../images/sheild.png";
import receiptIcon from "../images/receipt.png";
import phoneScreenshot from "../images/tipmaid-phone-screenshot.png";
import logoBlue from "../images/tipmaidlogo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import YouTube from "react-youtube";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import "../landingpage.css";

const LandingPage = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const navigationHandler = () => {
    navigate("/contact");
  };
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const isSmallScreen = screenWidth <= 576;
  const isMediumScreen = screenWidth > 576 && screenWidth <= 1275;

  let playerWidth;
  let playerHeight;

  if (isSmallScreen) {
    playerWidth = "100%";
    playerHeight = "200";
  } else if (isMediumScreen) {
    playerWidth = "80%";
    playerHeight = "360";
  } else {
    playerWidth = "640";
    playerHeight = "390";
  }
  // const opts = {
  //   height: playerHeight,
  //   width: playerWidth,
  //   // playerVars: {
  //   //   autoplay: 1,
  //   //   mute: 1,
  //   //   rel: 0,
  //   // },
  // };
  const opts = {
    width: "100%",
    height: "100%",
  };
  const styles = {
    shadow: {
      boxShadow:
        "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
    },
  };
  return (
    <div className="landing-page">
      <Header />
      {/* ------------------------------------------ TOP CONTENT ------------------------------------ */}
      <Row className="m-0 w-100 p-3 p-md-5 justify-content-between bg-light">
        <Col md={6} className="p-0">
          <h1 className="mt-5 pt-md-5 pt-3 text-primary">
            <Row>
              <Col>
                <span>
                  Seamless digital tipping <br className="d-none d-md-inline" />{" "}
                  for your <span className="text-success">Hotel!</span>
                </span>
              </Col>
            </Row>
          </h1>
          <p className="mt-4 text-muted">
            Empower your team with additional income and delight your guests
            with a convenient experience. Digital tips, private reviews, robust
            reporting, and compliance for the future of hospitality.
          </p>
          <Button
            variant="success"
            className="rounded-5 p-2 px-3 mt-4 d-md-inline d-none"
            style={styles.shadow}
            onClick={navigationHandler}
          >
            Request a Demo
          </Button>
        </Col>
        <Col md={6}>
          <Image className="mt-2 mt-md-5" fluid src={screenshot} />
          <Row className="justify-content-center">
            <Col xs="auto">
              <Button
                variant="success"
                className="rounded-5 p-2 px-3 my-4 d-md-none d-inline"
                style={styles.shadow}
                onClick={navigationHandler}
              >
                Request a Demo
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* ------------------------------------------ SECOND HEADING ------------------------------------ */}
      <Row className="m-0  justify-content-center ">
        <Col md={6} className="p-0">
          <h1 className="text-center text-primary mt-5 fs-3 fs-md-1">
            <b className="fw-medium">Streamline the Tipping Process</b>
          </h1>
          <p className="text-center px-3 mt-3 fs-5 text-muted">
            Our digital tipping solution offers a range of features to enhance
            the tipping experience for both guests and hotel staff
          </p>
        </Col>
      </Row>
      {/* ----------------------------------------------- CARDS ---------------------------------------- */}
      <Row className="m-0 p-5 justify-content-md-around justify-content-center">
        <Col md={3} xs={11} className="hover-effect my-2 my-md-0">
          <Row className="mb-2 justify-content-md-start justify-content-center">
            <Col xs={2}>
              <Image src={walletIcon} fluid />
            </Col>
            <Col xs="auto" className="text-primary fs-5 fw-medium p-0">
              Seamless Tipping
            </Col>
          </Row>
          <p className="text-dark">
            Tipmaid allows guests to easily tip hotel staff with just a few
            taps, eliminating the need for cash or physical receipts.
          </p>
        </Col>
        <Col md={3} xs={11} className="hover-effect my-2 my-md-0">
          <Row className="mb-2 justify-content-md-start justify-content-center">
            <Col xs={2}>
              <Image src={sheildIcon} fluid />
            </Col>
            <Col xs="auto" className="text-primary fs-5 fw-medium p-0">
              Secure Payments
            </Col>
          </Row>
          <p className="text-dark">
            Tipmaid uses industry-leading security to protect guest payments.
          </p>
        </Col>
        <Col md={3} xs={11} className="hover-effect my-2 my-md-0">
          <Row className="mb-2 justify-content-md-start justify-content-center">
            <Col xs={2}>
              <Image src={receiptIcon} fluid />
            </Col>
            <Col xs="auto" className="text-primary fs-5 fw-medium p-0">
              Transparent Reporting
            </Col>
          </Row>
          <p className="text-dark">
            Hotel staff can easily track and manage their tips, with detailed
            reports on tipping activity and payouts.
          </p>
        </Col>
      </Row>
      {/* ------------------------------------------ SCREENSHOT SECTION --------------------------------- */}
      <Row className="m-0 p-4 p-md-5 bg-primary text-light justify-content-md-between justify-content-center align-items-center">
        <Col md={6}>
          <h1 className="fw-medium">
            Boost Employee Tips with <br className="d-none d-md-inline" />
            tipmaid
          </h1>
          <p className="mt-4 fs-6">
            The tipping process is built within a simple chat interface, where
            guests can select their tip amount and leave a private review. Tips
            are dispersed directly to the team member's bank account, including
            Cash App and Chime accounts.
          </p>
          <div className="my-1 mt-5">
            <FontAwesomeIcon icon={faCheck} className="me-3" />
            <span>No need to download any app</span>
          </div>
          <div className="my-1">
            <FontAwesomeIcon icon={faCheck} className="me-3" />
            <span>Customizeable chatbot interface</span>
          </div>
          <div className="my-1">
            <FontAwesomeIcon icon={faCheck} className="me-3" />
            <span>Real time tip tracking and reporting</span>
          </div>
          <div className="my-1">
            <FontAwesomeIcon icon={faCheck} className="me-3" />
            <span>Seamless digital payment options</span>
          </div>
          <Row className="justify-content-center">
            <Col xs="auto">
              <Button
                variant="light"
                className="rounded-5 p-2 px-3 mt-5 text-primary d-none d-md-inline"
                onClick={navigationHandler}
              >
                Contact Now
              </Button>
            </Col>
          </Row>
        </Col>
        <Col md={4} xs={10} className="text-center my-4 my-md-none">
          <Image src={phoneScreenshot} fluid className="w-75" />
        </Col>
        <Row className="justify-content-center">
          <Col xs="auto">
            <Button
              variant="light"
              className="rounded-5 p-2 px-3 text-primary d-inline d-md-none"
              onClick={navigationHandler}
            >
              Contact Now
            </Button>
          </Col>
        </Row>
      </Row>
      {/* ------------------------------------------ YOUTUBE SECTION ------------------------------------ */}
      <Row className="m-0 p-5 my-5 align-items-center justify-content-md-evenly justify-content-center">
        <Col xs={10} md={6}>
          <div
            style={{
              position: "relative",
              paddingBottom: "56.25%",
              height: 0,
              overflow: "hidden",
              maxWidth: "100%",
              backgroundColor: "#000",
            }}
          >
            <iframe
              src="https://player.vimeo.com/video/999180634?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              title="Client Dashboard Tutorial"
            ></iframe>
          </div>
          {/* <ReactPlayer url="https://vimeo.com/999180634" {...opts} /> */}
        </Col>
        <Col md={4} className="mt-5 mt-md-none">
          <h2 className="fs-3 text-primary my-3">Easy And Simple To Use</h2>
          <p>
            Check out our step-by-step video tutorials to get your hotel staff
            up and running with our digital tipping solution in no time
          </p>
          {/* <Row className="justify-content-center my-4">
            <Col xs="auto">
              <Button className="rounded-5 px-3 py-2">Read FAQ</Button>
            </Col>
          </Row> */}
        </Col>
      </Row>
      {/* ----------------------------------------------  FOOTER ------------------------------------------------------- */}
      <Row className=" p-0 m-0 pb-xs-2 mt-4">
        <Col className="text-center">
          <Image
            src={logoBlue}
            alt="TipMaid Logo"
            width="250"
            height="250"
            fluid
          />
          <Row className="mt-5 justify-content-center">
            <Col xs={3} md={1}>
              <h4 className="d-inline fw-light">
                <a
                  href="/terms"
                  target="blank"
                  className="fs-5 fs-xs-5 text-primary link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                >
                  Terms
                </a>
              </h4>
            </Col>
            <Col xs={3} md={2}>
              <h4 className="d-inline-block fw-light">
                <a
                  href="/privacyPolicy"
                  target="blank"
                  className="fs-5 fs-xs-5 text-primary link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                >
                  Privacy Policy
                </a>
              </h4>
            </Col>
            <Col xs={3} md={1}>
              <h4 className="d-inline fw-light">
                <a
                  onClick={navigationHandler}
                  target="blank"
                  className="fs-5 fs-xs-5 text-primary link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                >
                  Contact
                </a>
              </h4>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="fs-7 text-center pb-1 ">
        <hr className="mb-1" />
        All Rights Reserved By{" "}
        <a
          className="text-success text-decoration-none me-1"
          target="blank"
          href="https://dock.bot"
        >
          Dock, Inc
        </a>
        &copy; {new Date().getFullYear()}
      </div>
    </div>
  );
};

export default LandingPage;
