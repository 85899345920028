import {
  faCheck,
  faMagnifyingGlass,
  faPenToSquare,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FloatingLabel,
  ListGroup,
  Modal,
  Row,
  Form,
  Alert,
  InputGroup,
} from "react-bootstrap";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { useDispatch, useSelector } from "react-redux";
import {
  createMaid,
  deleteMaid,
  getAllMaids,
  updateMaid,
} from "../redux/Actions/maidAction";
import {
  calculateAverageRating,
  generateServiceName,
} from "../controllers/addingnew";

const AttendantsList = ({ toAddMaid, toDeleteMaid }) => {
  const [validated, setValidated] = useState(false);
  const [editValidated, setEditValidated] = useState(false);
  const [serviceNameError, setServiceNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredMaids, setFilteredMaids] = useState([]);
  const [updatedFirstName, setUpdatedFirstName] = useState("");
  const [updatedLastName, setUpdatedLastName] = useState("");
  const [show, setShow] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [maidToDelete, setMaidToDelete] = useState(null);
  const [maidToEdit, setMaidToEdit] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    serviceName: "",
    type: "maid",
    email: "",
    employId: "NaN",
  });
  const [confirmationMessage, setConfirmationMessage] = useState("");

  const login = useSelector((state) => state.userLogin);
  const maidsList = useSelector((state) => state.maidsList);
  const maidCreate = useSelector((state) => state.maidCreate);
  const maidUpdate = useSelector((state) => state.maidUpdate);
  const { loading, error, maids: allMaids, success } = maidsList;
  const { success: createSuccess } = maidCreate;
  const { success: updateSuccess, loading: updateLoading } = maidUpdate;
  const { userInfo } = login;

  const dispatch = useDispatch();
  const maids = allMaids.filter((maid) => maid.type == "maid");
  const hotelId = userInfo.isAdmin == true ? userInfo._id : userInfo.hotel;

  const handleFirstNameChange = (e) => {
    const newFirstName = e.target.value;
    const generatedServiceName = generateServiceName(
      newFirstName,
      formData.lastName,
      maids
    );
    setFormData((prevFormData) => ({
      ...prevFormData,
      firstName: newFirstName,
      serviceName: generatedServiceName,
    }));
  };
  const handleLastNameChange = (e) => {
    const newLastName = e.target.value;
    const firstLetterOfLastName = newLastName.charAt(0).toUpperCase();
    const generatedServiceName = generateServiceName(
      formData.firstName,
      newLastName,
      maids
    );
    setFormData((prevFormData) => ({
      ...prevFormData,
      lastName: newLastName,
      serviceName: generatedServiceName,
    }));
  };
  const handleEmailChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      email: e.target.value,
    }));
    setServiceNameError(false);
  };
  const handleEmployIdChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      employId: e.target.value,
    }));
    // setServiceNameError(false);
  };
  const handleClose = () => {
    setValidated(false);
    setShow(false);
    setServiceNameError(false);
    setEmailError(false);
    setFormData({
      firstName: "",
      lastName: "",
      serviceName: "",
      type: "maid",
      email: "",
      employId: "NaN",
    });
  };

  const handleDeleteModal = () => setDeleteModal(false);
  const handleEditClose = () => setEditModal(false);

  const handleEditShow = () => setEditModal(true);

  const handleEdit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      dispatch(updateMaid(maidToEdit._id, updatedFirstName, updatedLastName));

      handleEditClose();
    }
    setEditValidated(true);
  };
  const handleDelete = () => {
    if (maidToDelete) {
      dispatch(deleteMaid(maidToDelete));
      setMaidToDelete(null);
      handleDeleteModal();
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      const isDuplicateService = maids.some(
        (maid) =>
          maid.serviceName.toLowerCase().replace(/\s/g, "") ===
          formData.serviceName.toLowerCase().replace(/\s/g, "")
      );

      const isDuplicateEmail = maids.some(
        (maid) =>
          maid.email.toLowerCase().replace(/\s/g, "") ===
          formData.email.toLowerCase().replace(/\s/g, "")
      );

      if (isDuplicateService) {
        setServiceNameError(true);
        setValidated(false);
        return;
      } else if (isDuplicateEmail) {
        setEmailError(true);
        setServiceNameError(false);
        setValidated(false);
        return;
      } else {
        // Reset the service name error state
        setServiceNameError(false);
        setEmailError(false);
        // Dispatch the action after checking validation
        dispatch(
          createMaid(
            formData.firstName,
            formData.lastName,
            formData.serviceName,
            formData.type,
            formData.email,
            formData.employId,
            hotelId
          )
        );
        dispatch(getAllMaids(hotelId));
        // Show confirmation message for 2-3 seconds
        setConfirmationMessage("Attendant added successfully!");
        setTimeout(() => {
          setConfirmationMessage("");
        }, 3000);
        handleClose();
      }
    }
    setValidated(true);
  };

  useEffect(() => {
    dispatch(getAllMaids(hotelId));
    if (maidToEdit != null) {
      setUpdatedFirstName(maidToEdit.firstName);
      setUpdatedLastName(maidToEdit.lastName);
    }
  }, [
    dispatch,
    // userInfo._id,
    // searchQuery,
    success,
    hotelId,
    // createSuccess,
    maidToEdit,
    updateSuccess,
  ]);
  const styles = {
    shadow: {
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    },
  };
  return (
    <>
      <Alert
        className="mt-3"
        show={!!confirmationMessage}
        variant={
          confirmationMessage.includes("successfully") ? "success" : "danger"
        }
        onClose={() => setConfirmationMessage("")}
        // dismissible
      >
        {confirmationMessage}
      </Alert>
      {/* --------------------------------------------- EDIT MODAL --------------------------------------------- */}
      <Modal
        show={editModal}
        onHide={handleEditClose}
        backdrop="static"
        centered
      >
        <Modal.Header className="bg-primary">
          <Modal.Title className="mx-auto text-light">Edit Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={editValidated} onSubmit={handleEdit}>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    required
                    pattern="[A-Za-z]+"
                    type="text"
                    value={updatedFirstName}
                    onChange={(e) => {
                      setUpdatedFirstName(e.target.value);
                    }}
                    placeholder="Enter First Name"
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    required
                    pattern="[A-Za-z]+"
                    type="text"
                    value={updatedLastName}
                    onChange={(e) => {
                      setUpdatedLastName(e.target.value);
                    }}
                    placeholder="Enter Last Name"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col sm="auto" className="ms-auto">
                <Button
                  variant="secondary"
                  className="mx-3"
                  onClick={handleEditClose}
                >
                  Close
                </Button>
                <Button variant="success" type="submit">
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ------------------------------------------ DELETE MODAL ----------------------------------------- */}
      <Modal
        show={deleteModal}
        onHide={handleDeleteModal}
        backdrop="static"
        centered
      >
        <Modal.Body className="p-4">
          <b>Are you sure you want to remove this attendant from team?</b>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* --------------------------------------------- ADD NEW MODAL ------------------------------------------- */}
      <Modal show={show} onHide={handleClose} backdrop="static" centered>
        <Modal.Header className="bg-primary">
          <Modal.Title className="mx-auto text-light">
            Add a new attendant
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <FloatingLabel
              className="my-3"
              controlId="floatingName"
              label="First name"
              value={formData.firstName}
              onChange={handleFirstNameChange}
            >
              <Form.Control
                required
                type="text"
                placeholder="Name"
                pattern="[A-Za-z]+"
              />
              {/* <Form.Control.Feedback type="invalid">
                This field is required*
              </Form.Control.Feedback> */}
            </FloatingLabel>
            <FloatingLabel
              className="my-3"
              controlId="floatingName"
              label="Last name"
              value={formData.lastName}
              onChange={handleLastNameChange}
            >
              <Form.Control
                required
                type="text"
                placeholder="Name"
                pattern="[A-Za-z]+"
              />
              {/* <Form.Control.Feedback type="invalid">
                This field is required*
              </Form.Control.Feedback> */}
            </FloatingLabel>
            <FloatingLabel
              className="my-3 text-muted"
              controlId="floatingId"
              label="Employ Id (optional)"
              value={formData.employId}
              onChange={handleEmployIdChange}
            >
              <Form.Control type="text" placeholder="#123" />
            </FloatingLabel>

            <Form.Group className="my-3" controlId="">
              <Form.Control
                required
                type="text"
                placeholder="Service name"
                value={formData.serviceName}
                isInvalid={serviceNameError}
                readOnly={!serviceNameError}
                onChange={(e) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    serviceName: e.target.value,
                  }));
                  // setServiceNameError(false);
                }}
              />
              {
                <Form.Text
                  className={serviceNameError ? "text-danger" : "text-muted"}
                >
                  {serviceNameError
                    ? "This name is not available, please edit."
                    : "This will be a unique name."}
                </Form.Text>
              }
            </Form.Group>
            <FloatingLabel
              className="my-3"
              controlId="floatingInput"
              label="Email address"
              onChange={handleEmailChange}
            >
              <Form.Control
                required
                type="email"
                placeholder="name@example.com"
              />
              {emailError ? (
                <Form.Text className="text-danger">
                  This email is alreday in use
                </Form.Text>
              ) : (
                <></>
              )}
              <Form.Control.Feedback type="invalid">
                Please enter a valid email*
              </Form.Control.Feedback>
            </FloatingLabel>
            <Row className="my-3">
              <Col sm="auto" className="ms-auto">
                <Button
                  variant="secondary"
                  className="me-3"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button variant="primary" type="submit">
                  Add
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      {/* -------------------------------------------- Add new button ------------------------------------------------ */}
      <Row className="m-0 mt-4">
        <Col>
          {userInfo.isAdmin === true || toAddMaid === true ? (
            <>
              <Row className="align-items-center mt-4">
                <Col sm={3} className="ms-auto p-0">
                  <InputGroup className="ms-auto border border-1 rounded-4 p-1">
                    <Button
                      className="bg-transparent border-0 p-1"
                      id="button-addon1"
                    >
                      <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        className="text-primary mx-2"
                      />
                    </Button>
                    <Form.Control
                      className="border-0 p-0"
                      aria-label="search"
                      aria-describedby="basic-addon1"
                      placeholder="Search"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </InputGroup>
                </Col>
                <Col md="auto">
                  <Button
                    className="rounded-4"
                    variant="success"
                    onClick={() => {
                      setShow(true);
                      setValidated(false);
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} className="me-2" /> Add New
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
          {/* -------------------------- Top Row --------------------------------- */}
          <Row className="py-4">
            <Col className="p-4 rounded-4" style={styles.shadow}>
              <Row>
                <Col md={2}>
                  <b className="text-primary fs-5">Employ Id</b>
                </Col>
                <Col md={3}>
                  <b className="text-primary fs-5">Name</b>
                </Col>
                <Col md={2}>
                  <b className="text-primary fs-5">Email</b>
                </Col>
                <Col md={2} className="text-center">
                  <b className="text-primary fs-5">@</b>
                </Col>
                <Col md={2}>
                  <b className="text-primary fs-5">Ratings</b>
                </Col>
              </Row>
              <hr className="my-3" />
              {/* ----------------------------------------------- Maids List------------------------------------------ */}
              <Row>
                <Col>
                  {loading ? (
                    <Loader />
                  ) : error ? (
                    <Message>{error}</Message>
                  ) : (
                    <>
                      {maids
                        .filter((maid) =>
                          maid.serviceName
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase())
                        )
                        .map((maid, index) => (
                          <>
                            <Row key={index} className="align-items-center">
                              <Col md={2}>
                                <span className="text-muted">
                                  {maid.employId}
                                </span>
                              </Col>
                              <Col md={3}>
                                <Row className="align-items-center">
                                  <Col sm={1} className="pe-0 m-0">
                                    <div
                                      className={
                                        maid.chargesStatus
                                          ? "rounded-circle bg-success display-flex"
                                          : "rounded-circle bg-danger"
                                      }
                                      style={{ width: "8px", height: "8px" }}
                                    ></div>
                                  </Col>
                                  <Col sm="auto" className="ps-1">
                                    {maid.firstName + " " + maid.lastName}
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={2} className="p-0 text-muted fst-italic">
                                <a>{maid.email}</a>
                              </Col>
                              <Col
                                md={2}
                                className="text-success ps-3 text-center"
                              >
                                {maid.serviceName}
                              </Col>
                              <Col md={1} className="text-success text-center">
                                {calculateAverageRating(maid.ratings).toFixed(
                                  1
                                )}
                              </Col>
                              <Col className="ms-auto" sm="auto">
                                {userInfo.isAdmin == true ||
                                toAddMaid == true ? (
                                  <>
                                    <Button
                                      className="mx-2"
                                      variant="outline-dark"
                                      onClick={() => {
                                        setMaidToEdit(maid);
                                        setEditModal(true);
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faPenToSquare} />
                                    </Button>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {userInfo.isAdmin == true ||
                                toDeleteMaid == true ? (
                                  <>
                                    <Button
                                      className="mx-2"
                                      variant="outline-danger"
                                    >
                                      <FontAwesomeIcon
                                        onClick={() => {
                                          setDeleteModal(true);
                                          setMaidToDelete(maid._id);
                                        }}
                                        icon={faTrash}
                                      />
                                    </Button>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Col>
                            </Row>
                            <hr className="my-2" />
                          </>
                        ))}
                    </>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default AttendantsList;
