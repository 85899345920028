import React, { useState, useEffect } from "react";
import {
  faArrowUpRightFromSquare,
  faCheck,
  faChevronDown,
  faChevronUp,
  faCircleCheck,
  faCircleXmark,
  faLink,
  faMagnifyingGlass,
  faPenToSquare,
  faPlus,
  faTrash,
  faXmark,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { faHandshake } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import Register from "./Register";
import {
  deleteUser,
  getUserDetails,
  listUsers,
  updateHotel,
} from "../redux/Actions/userAction";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  HOTEL_UPDATE_RESET,
  USER_REGISTER_RESET,
} from "../redux/Constants/userconstants";
import { getDocumentStatus } from "../controllers/docstatus";
import axios from "axios";
import { BACKEND_URL } from "../config";
import { generatePassword } from "../controllers/passwordGenerator";
import FileInputField from "../components/FileInputField";
import ClientsCard from "../components/ClientsCard";
import MaidsCard from "../components/MaidsCard";
import { getAllStaff } from "../redux/Actions/maidAction";
import PaymentsCard from "../components/PaymentsCard";
import StaffDetail from "./StaffDetail";
import ClientDetail from "./ClientDetail";
import { setActiveTab } from "../redux/Actions/tabAcions";

const AdminDashboard = ({ onTabChange }) => {
  const [sendEmail, setSendEmail] = useState(false);
  const [userListUpdate, setUserListUpdate] = useState(false);
  const [createdPassword, setCreatedPassword] = useState("");
  const [userToDelete, setUserToDelete] = useState(null);
  const [userToDeleteName, setUserToDeleteName] = useState("");
  const [hotelToEdit, setHotelToEdit] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [valetLocationError, setValetLocationError] = useState(false);
  const [newLocationData, setNewLocationData] = useState({
    locationName: "",
    hotelName: "",
    address: "",
  });

  const [searchQuery, setSearchQuery] = useState("");
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [showValetLocations, setShowValetLocations] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [staffModal, setStaffModal] = useState(false);
  const [clientDetailsModal, setClientDetailsModal] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [locationImageUploading, setLocationImageUploading] = useState([]);
  const [updatedHotel, setUpdatedHotel] = useState({
    id: "",
    companyName: "",
    name: "",
    email: "",
    password: "",
    phoneNumber: "",
    location: "",
    accountStatus: false,
    services: {
      maids: false,
      valets: false,
      bellmen: false,
    },
    qrCodes: {
      forMaids: "",
      forValets: "",
      forBellmen: "",
      // forValetLocations: [{ location: "", qrCode: "", locationId: "" }],
    },
    valetLocations: [
      {
        locationName: "",
        hotelName: "",
        address: "",
        qrCode: "",
      },
    ],
  });
  const userList = useSelector((state) => state.userList);
  const { staff } = useSelector((state) => state.getAllStaff);
  const userInfo = useSelector((state) => state.userDetails);
  const { loading: userLoading, error: userError, user } = userInfo;
  const userId = user && user._id;
  const { loading, error, users, success } = userList;

  const hotels =
    users.length > 0 && user.isSuperAdmin
      ? users.filter((user) => !user.isSuperAdmin && !user.isPartner)
      : users.length > 0 && user.isPartner
      ? users.filter(
          (user) =>
            !user.isSuperAdmin && !user.isPartner && user.partnerId === userId
        )
      : [];

  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
  } = useSelector((state) => state.hotelUpdate);

  const dispatch = useDispatch();
  // --------------------------------------------------- MODAL HANDLERS ------------------------------------------------------
  const handleDeleteModal = () => setDeleteModal(false);
  const handleEditModal = () => {
    setEditModal(false);
    setHotelToEdit(null);
    setValetLocationError(false);
  };
  const handleClose = () => setShow(false);
  const handleStaffModal = () => setStaffModal(false);
  const handleClientDetailsModal = () => setClientDetailsModal(false);
  //---------------------------------------------- Event handler for checkbox changes ---------------------------------------
  const handleServiceCheckboxChange = (service) => {
    setUpdatedHotel((prevHotel) => {
      prevHotel.services[service] = !prevHotel.services[service];
      return { ...prevHotel };
    });
  };
  const handleAccountStatusChange = () => {
    setUpdatedHotel((prevHotel) => ({
      ...prevHotel,
      accountStatus: !prevHotel.accountStatus,
    }));
  };
  // --------------------------------------------------- EVENT HANDLERS ----------------------------------------------------
  const handleAddNew = () => {
    dispatch({ type: USER_REGISTER_RESET });
    setShow(true);
  };
  const handleDelete = () => {
    if (userToDelete) {
      dispatch(deleteUser(userToDelete));
      setUserToDelete(null);
      setUserToDeleteName("");
      setDeleteModal(false);
    }
  };
  const handleAddNewLocation = () => {
    const { locationName, hotelName, address } = newLocationData;
    if (
      locationName.trim() !== "" &&
      hotelName.trim() !== "" &&
      address.trim() !== ""
    ) {
      setValetLocationError(false);
      setUpdatedHotel((prevHotel) => ({
        ...prevHotel,
        valetLocations: [
          ...prevHotel.valetLocations,
          { locationName, hotelName, address },
        ],
      }));
      // Clear the form
      setNewLocationData({
        locationName: "",
        hotelName: "",
        address: "",
      });
    } else {
      setValetLocationError(true);
    }
  };

  const handleRemoveValetLocation = (indexToRemove) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to remove this valet location?"
    );
    if (isConfirmed) {
      setUpdatedHotel((prevHotel) => {
        const updatedValetLocations = [...prevHotel.valetLocations];
        updatedValetLocations.splice(indexToRemove, 1);

        return {
          ...prevHotel,
          valetLocations: updatedValetLocations,
        };
      });
    }
  };

  const handleEdit = (e) => {
    e.preventDefault();
    let updatedPassword = updatedHotel.password;
    let passwordGenerated = false;
    if (!updatedPassword) {
      updatedPassword = generatePassword(updatedHotel.companyName);
      passwordGenerated = true;
    }
    dispatch(
      updateHotel(
        hotelToEdit._id,
        updatedHotel.companyName,
        updatedHotel.name,
        updatedHotel.email,
        passwordGenerated ? updatedPassword : "",
        updatedHotel.phoneNumber,
        updatedHotel.location,
        updatedHotel.services,
        updatedHotel.qrCodes,
        updatedHotel.valetLocations,
        sendEmail
      )
    );
  };
  const handleImageUpload = async (e, service) => {
    const file = e.target.files[0];
    setUploadLoading(true);
    const formData = new FormData();
    formData.append("qrImage", file);

    try {
      const response = await axios.post(
        `${BACKEND_URL}api/upload/qrCodes`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const fileUrl = response.data.fileUrl;
      if (response.data.success) {
        setUploadLoading(false);
        setUpdatedHotel((prevHotel) => ({
          ...prevHotel,
          qrCodes: {
            ...prevHotel.qrCodes,
            [service]: fileUrl,
          },
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleValetLocationImageUpload = async (e, index) => {
    const file = e.target.files[0];
    const formData = new FormData();
    setLocationImageUploading((prevState) => {
      const updatedUploadingStatus = [...prevState];
      updatedUploadingStatus[index] = true;
      return updatedUploadingStatus;
    });
    formData.append("qrImage", file);
    try {
      const response = await axios.post(
        `${BACKEND_URL}api/upload/qrCodes`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const fileUrl = response.data.fileUrl;
      if (response.data.success) {
        setUpdatedHotel((prevHotel) => {
          const updatedValetLocations = [...prevHotel.valetLocations];
          updatedValetLocations[index] = {
            ...updatedValetLocations[index],
            qrCode: fileUrl,
          };

          return {
            ...prevHotel,
            valetLocations: updatedValetLocations,
          };
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLocationImageUploading((prevState) => {
        const updatedUploadingStatus = [...prevState];
        updatedUploadingStatus[index] = false;
        return updatedUploadingStatus;
      });
    }
  };
  const handleLinkForward = (ref, id) => {
    const link = `https://tipmaid.io/bot?ref=${ref}&hotel=${id}`;
    window.open(link, "_blank");
  };
  const getDevPartner = (partnerId) => {
    if (user) {
      const partnerUser = users.find((user) => user._id === partnerId);
      if (partnerUser) {
        return partnerUser.name;
      }
    }
    return "Not defined";
  };
  const handleView = (client) => {
    // dispatch(setActiveTab("#viewClient"));
    onTabChange("#viewClient", client);
  };
  useEffect(() => {
    dispatch(listUsers());
    dispatch(getAllStaff());
    dispatch(getUserDetails());
    const updatedFilteredUsers = hotels.filter((user) =>
      user.companyName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredUsers(updatedFilteredUsers);
  }, [dispatch, userListUpdate, success, searchQuery]);

  useEffect(() => {
    if (hotelToEdit != null) {
      console.log("Hotel to edit: ", hotelToEdit);
      const mapValetLocations = (locations) =>
        locations.map((location) =>
          typeof location === "string"
            ? { locationName: location, hotelName: "", address: "", qrCode: "" }
            : {
                locationName: location.locationName || "",
                hotelName: location.hotelName || "",
                address: location.address || "",
                qrCode: location.qrCode || "",
              }
        );
      setUpdatedHotel((prevHotel) => ({
        ...prevHotel,
        id: hotelToEdit._id,
        companyName: hotelToEdit.companyName,
        name: hotelToEdit.name,
        email: hotelToEdit.email,
        password: hotelToEdit.password,
        phoneNumber: hotelToEdit.phoneNumber,
        location: hotelToEdit.location,
        services: {
          maids: hotelToEdit.services?.maids || false,
          valets: hotelToEdit.services?.valets || false,
          bellmen: hotelToEdit.services?.bellmen || false,
        },
        qrCodes: {
          forMaids: hotelToEdit.qrCodes?.forMaids,
          forValets: hotelToEdit.qrCodes?.forValets,
          forBellmen: hotelToEdit.qrCodes?.forBellmen,
        },
        accountStatus: hotelToEdit.accountStatus || false,
        valetLocations: Array.isArray(hotelToEdit.valetLocations)
          ? mapValetLocations(hotelToEdit.valetLocations)
          : [],
      }));
      console.log("Updated hotel: ", updatedHotel);
    }
  }, [hotelToEdit]);

  useEffect(() => {
    dispatch(listUsers());
  }, [dispatch, updateSuccess]);

  const styles = {
    shadow: {
      boxShadow: " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    },
    shadowCard: {
      boxShadow:
        " rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
    },
  };
  return (
    <>
      {/* --------------------------------------------------- CLIENT DETAIL MODAL ------------------------------------------ */}
      <Modal
        show={clientDetailsModal}
        onHide={handleClientDetailsModal}
        backdrop="static"
        fullscreen
        centered
      >
        <Modal.Header className="bg-primary">
          <Row className="w-100 align-items-center">
            <Col className="text-center text-light fs-4"></Col>
            <Col md="auto" className="ms-auto p-0 d-inline">
              <Button
                variant="outline-light p-0 px-1  m-0 border-0"
                onClick={handleClientDetailsModal}
              >
                <FontAwesomeIcon className="fs-4" icon={faXmark} />
              </Button>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <ClientDetail staff={staff && staff} clients={hotels} />
        </Modal.Body>
      </Modal>
      {/* --------------------------------------------------- STAFF DETAIL MODAL ------------------------------------------ */}
      <Modal
        show={staffModal}
        onHide={handleStaffModal}
        backdrop="static"
        fullscreen
        centered
      >
        <Modal.Header className="bg-primary">
          <Row className="w-100 align-items-center">
            <Col className="text-center text-light fs-4"></Col>
            <Col md="auto" className="ms-auto p-0 d-inline">
              <Button
                variant="outline-light p-0 px-1  m-0 border-0"
                onClick={handleStaffModal}
              >
                <FontAwesomeIcon className="fs-4" icon={faXmark} />
              </Button>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <StaffDetail hotels={hotels} staff={staff} />
        </Modal.Body>
      </Modal>
      {/* --------------------------------------------------- UPDATE DETAILS MODAL ---------------------------------- */}
      <Modal
        fullscreen
        show={editModal}
        onHide={handleEditModal}
        backdrop="static"
        centered
      >
        <Modal.Header className="bg-primary">
          <Row className="w-100 align-items-center">
            <Col className="text-center text-light fs-4">Edit Details</Col>
            <Col md="auto" className="ms-auto p-0 d-inline">
              <Button
                variant="outline-light p-0 px-1  m-0 border-0"
                onClick={handleEditModal}
              >
                <FontAwesomeIcon className="fs-4" icon={faXmark} />
              </Button>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body className="px-5">
          {updateError && <Message>{updateError}</Message>}
          <Form onSubmit={handleEdit} className="p-4">
            <Row>
              <Col sm={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="fw-semibold">Hotel Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={updatedHotel.companyName}
                    onChange={(e) =>
                      setUpdatedHotel({
                        ...updatedHotel,
                        companyName: e.target.value,
                      })
                    }
                    placeholder="Enter Company Name"
                  />
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="fw-semibold">
                    Contact Person Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={updatedHotel.name}
                    onChange={(e) =>
                      setUpdatedHotel({
                        ...updatedHotel,
                        name: e.target.value,
                      })
                    }
                    placeholder="Enter Contact Person Name"
                  />
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="fw-semibold">Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    value={updatedHotel.email}
                    onChange={(e) =>
                      setUpdatedHotel({
                        ...updatedHotel,
                        email: e.target.value,
                      })
                    }
                    placeholder="Enter Email"
                  />
                  <Form.Text className="text-muted fst-italic">
                    This is the login email*
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="fw-semibold">Phone Number</Form.Label>
              <Form.Control
                type="number"
                value={updatedHotel.phoneNumber}
                onChange={(e) =>
                  setUpdatedHotel({
                    ...updatedHotel,
                    phoneNumber: e.target.value,
                  })
                }
                placeholder="Enter Phone Number"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="fw-semibold">Address Line</Form.Label>
              <Form.Control
                type="text"
                value={updatedHotel.location}
                onChange={(e) =>
                  setUpdatedHotel({ ...updatedHotel, location: e.target.value })
                }
                placeholder="Enter Location"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="fw-semibold">
                Services Selected :
              </Form.Label>
              <Row className="justify-content-center">
                <Col sm={2} className="border border-1 rounded-4 p-3 mx-3">
                  <Form.Check
                    type="checkbox"
                    label="Attendants Dashboard"
                    checked={updatedHotel.services?.maids || false}
                    onChange={() => handleServiceCheckboxChange("maids")}
                  />
                  {updatedHotel.services?.maids && (
                    <Row className="mt-4 align-items-center">
                      <Col xs="auto">
                        <FileInputField
                          uploaded={
                            updatedHotel.qrCodes?.forMaids == "" ? false : true
                          }
                          accept="image/*"
                          onChange={(e) => handleImageUpload(e, "forMaids")}
                        />
                      </Col>
                      <Col xs="auto" className="p-0">
                        <Button
                          className="p-1 px-2 border-0 fs-5"
                          variant="outline-primary"
                          onClick={() =>
                            handleLinkForward("maid", updatedHotel.id)
                          }
                        >
                          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                        </Button>
                      </Col>
                      <Col xs="auto">{uploadLoading ? <Loader /> : <></>}</Col>
                    </Row>
                  )}
                </Col>
                <Col sm={2} className="border border-1 rounded-4 p-3 mx-3">
                  <Form.Check
                    type="checkbox"
                    label="Valet Dashboard"
                    checked={updatedHotel.services?.valets || false}
                    onChange={() => handleServiceCheckboxChange("valets")}
                  />
                  {updatedHotel.services?.valets && (
                    <Row className="mt-4 align-items-center">
                      <Col xs="auto">
                        <FileInputField
                          uploaded={
                            updatedHotel.qrCodes?.forValets == "" ? false : true
                          }
                          accept="image/*"
                          onChange={(e) => handleImageUpload(e, "forValets")}
                        />
                      </Col>
                      <Col xs="auto" className="p-0">
                        <Button
                          className="p-1 px-2 border-0 fs-5"
                          variant="outline-primary"
                          onClick={() =>
                            handleLinkForward("valet", updatedHotel.id)
                          }
                        >
                          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                        </Button>
                      </Col>
                      <Col xs="auto">{uploadLoading ? <Loader /> : <></>}</Col>
                    </Row>
                  )}
                </Col>
                <Col sm={2} className="border border-1 rounded-4 p-3 mx-3">
                  <Form.Check
                    type="checkbox"
                    label="Bellmen Dashboard"
                    checked={updatedHotel.services?.bellmen || false}
                    onChange={() => handleServiceCheckboxChange("bellmen")}
                  />
                  {updatedHotel.services?.bellmen && (
                    <Row className="mt-4 align-items-center">
                      <Col xs="auto">
                        <FileInputField
                          uploaded={
                            updatedHotel.qrCodes?.forBellmen == ""
                              ? false
                              : true
                          }
                          accept="image/*"
                          onChange={(e) => handleImageUpload(e, "forBellmen")}
                        />
                      </Col>
                      <Col xs="auto" className="p-0">
                        <Button
                          className="p-1 px-2 border-0 fs-5"
                          variant="outline-primary"
                          onClick={() =>
                            handleLinkForward("bellman", updatedHotel.id)
                          }
                        >
                          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                        </Button>
                      </Col>
                      <Col xs="auto">{uploadLoading ? <Loader /> : <></>}</Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Form.Group>
            {updatedHotel.services?.valets && (
              <Form.Group className="rounded-4 p-3 bg-light mt-5">
                <Row>
                  <Col xs="auto">
                    <Form.Label>
                      Valet Locations
                      <span className="fst-italic fs-6 text-muted">
                        (Optional)
                      </span>
                    </Form.Label>
                  </Col>
                  <Col xs="auto" className="ms-auto">
                    <Button
                      className="text-secondary"
                      variant="link"
                      onClick={() => setShowValetLocations(!showValetLocations)}
                    >
                      <FontAwesomeIcon
                        icon={showValetLocations ? faChevronUp : faChevronDown}
                        className="me-2"
                      />
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Control
                      type="text"
                      value={newLocationData.locationName}
                      onChange={(e) =>
                        setNewLocationData({
                          ...newLocationData,
                          locationName: e.target.value,
                        })
                      }
                      placeholder="Enter Valet Location"
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      value={newLocationData.hotelName}
                      onChange={(e) =>
                        setNewLocationData({
                          ...newLocationData,
                          hotelName: e.target.value,
                        })
                      }
                      placeholder="Enter Hotel Name"
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      value={newLocationData.address}
                      onChange={(e) =>
                        setNewLocationData({
                          ...newLocationData,
                          address: e.target.value,
                        })
                      }
                      placeholder="Enter Hotel Address"
                    />
                  </Col>
                  <Col>
                    <Button
                      onClick={handleAddNewLocation}
                      className="rounded-3"
                    >
                      <FontAwesomeIcon icon={faPlus} className="me-1" /> Add
                    </Button>
                  </Col>
                </Row>
                {valetLocationError && (
                  <span className="text-danger fst-italic fs-7 mx-2">
                    All feilds are required*
                  </span>
                )}
                {/* ----------------------------------------- VALET LOCATIONS --------------------------------- */}
                <Row className="mt-2 p-3 w-100">
                  <Col
                    className={`transition ${
                      showValetLocations ? "visible" : "hidden"
                    }`}
                  >
                    {updatedHotel.valetLocations.length > 0 && (
                      <>
                        <Row>
                          <Col xs={2}>Location</Col>
                          <Col xs={2}>Hotel Name</Col>
                          <Col xs={3}>Address</Col>
                          <Col xs={2} className="text-center">
                            QR code
                          </Col>
                        </Row>
                      </>
                    )}
                    {updatedHotel.valetLocations.length > 0
                      ? updatedHotel.valetLocations.map((location, index) => (
                          <div key={index}>
                            <Row className="border border-1 rounded-4 p-1 my-2 bg-white align-items-center">
                              <Col xs={2} className="fw-semibold">
                                {location.locationName}
                              </Col>
                              <Col xs={2}>{location.hotelName}</Col>
                              <Col xs={3}>{location.address}</Col>
                              <Col xs={2} className="text-center">
                                {location.qrCode ? (
                                  <FontAwesomeIcon
                                    icon={faCircleCheck}
                                    className="text-success"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    icon={faCircleXmark}
                                    className="text-danger"
                                  />
                                )}
                              </Col>
                              <Col xs={3}>
                                <Row>
                                  <Col>
                                    <FileInputField
                                      uploaded={location.qrCode ? true : false}
                                      accept="image/*"
                                      onChange={(e) =>
                                        handleValetLocationImageUpload(e, index)
                                      }
                                    />
                                  </Col>
                                  <Col>
                                    {locationImageUploading[index] ? (
                                      <Loader />
                                    ) : null}
                                  </Col>
                                  <Col
                                    onClick={() =>
                                      handleRemoveValetLocation(index)
                                    }
                                  >
                                    <FontAwesomeIcon
                                      className="text-danger border-0 hover-effect"
                                      icon={faTrash}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        ))
                      : null}
                  </Col>
                </Row>
              </Form.Group>
            )}
            <Row className="mt-5 mb-3">
              <Col sm="auto" className="mx-auto">
                <Button
                  className="px-2 ms-3 rounded-3"
                  variant="success"
                  type="submit"
                  onClick={() => setSendEmail(false)}
                >
                  {updateLoading ? (
                    "Saving..."
                  ) : updateSuccess ? (
                    <span>
                      <FontAwesomeIcon className="me-2" icon={faCheck} />
                      Saved
                    </span>
                  ) : (
                    "Save Changes"
                  )}
                </Button>
                <Button
                  className="px-2 ms-3 rounded-3"
                  variant="primary"
                  type="submit"
                  onClick={() => setSendEmail(true)}
                >
                  {updateLoading ? (
                    "Saving..."
                  ) : updateSuccess ? (
                    <span>
                      <FontAwesomeIcon className="me-2" icon={faCheck} />
                      Saved
                    </span>
                  ) : (
                    "Save and Email"
                  )}
                </Button>
              </Col>
            </Row>
            {/* <hr />
            <Row>
              <Col sm="auto">PandaDoc Status</Col>
              <Col sm="auto">
                <Badge bg="danger">Pending</Badge>
              </Col>
              <Col sm="auto" className="ms-3">
                Google Form Status
              </Col>
              <Col sm="auto">
                <Badge bg="danger">Pending</Badge>
              </Col>
            </Row> */}
          </Form>
        </Modal.Body>
      </Modal>
      {/* --------------------------------------------------- DELETE MODAL ------------------------------------------ */}
      <Modal
        show={deleteModal}
        onHide={handleDeleteModal}
        backdrop="static"
        centered
      >
        <Modal.Body className="p-4 text-center">
          <b>
            Are you sure you want to remove
            <span className="text-danger mx-1">
              {userToDeleteName != "" && userToDeleteName}
            </span>
            ?
          </b>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* --------------------------------------------------- ADD NEW MODAL ----------------------------------------- */}
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Send An Invite</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Register
            setUserListUpdate={setUserListUpdate}
            setCreatedPassword={setCreatedPassword}
            userId={userId}
          />
        </Modal.Body>
      </Modal>
      {/* --------------------------------------------------  CARDS ------------------------------------------------- */}
      {userId == "65b949bd84febb1899b71e0e" && (
        <Row className="justify-content-evenly m-0 mt-5">
          <Col sm={3}>
            <Card
              className="rounded-4 border-0 hover-effect"
              style={styles.shadow}
              onClick={() => {
                staff.length <= 0
                  ? alert("Please wait for the data")
                  : setClientDetailsModal(true);
              }}
            >
              <Card.Body className="p-4">
                <ClientsCard clients={hotels} />
              </Card.Body>
            </Card>
          </Col>
          <Col sm={3}>
            <Card
              className="rounded-4 border-0 hover-effect"
              style={styles.shadow}
              onClick={() => {
                staff.length <= 0
                  ? alert("Please wait for the data")
                  : setStaffModal(true);
              }}
            >
              <Card.Body className="p-4">
                <MaidsCard staff={staff} />
              </Card.Body>
            </Card>
          </Col>
          <Col sm={3}>
            <Card
              className="rounded-4 border-0 hover-effect"
              style={styles.shadow}
            >
              <Card.Body className="p-4">
                <PaymentsCard />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      {/* ----------------------------------------------- ADD NEW BUTTON --------------------------------- */}
      <Row className="px-5 align-items-center m-0 mt-5">
        <Col sm={3} className="ms-auto">
          <InputGroup className="ms-auto border border-1 rounded-5 p-1">
            <Button className="bg-transparent border-0" id="button-addon1">
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                className="text-primary"
              />
            </Button>
            <Form.Control
              className="border-0"
              aria-label="search"
              aria-describedby="basic-addon1"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </InputGroup>
        </Col>
        <Col sm="auto" className="ms-2 me-3">
          <Button className="px-4 rounded-5" onClick={handleAddNew}>
            <FontAwesomeIcon icon={faPlus} className="me-2" /> Add new
          </Button>
        </Col>
      </Row>
      {/* ---------------------------------------------- HOTELS TABLE ------------------------------------------ */}
      <Row className="p-4 m-0 my-3 ">
        <Col className="p-3 py-4  rounded-4" style={styles.shadow}>
          <Row>
            <Col sm="auto" className="me-2 fw-bold">
              #
            </Col>
            <Col sm={1} className="fw-bold">
              Date
            </Col>
            <Col sm={3} className="fw-bold">
              Client Name
            </Col>
            <Col sm={2} className="fw-bold">
              Contact Person
            </Col>
            <Col sm={3} className="fw-bold">
              Location
            </Col>
            <Col xs={2} className="text-center fw-bold">
              Actions
            </Col>
          </Row>
          <hr className="mt-3 mb-1" />
          {loading ? (
            <Row className="justify-content-center">
              <Col sm="auto">
                <Loader />
              </Col>
            </Row>
          ) : error ? (
            <Row className="justify-content-center">
              <Col sm="auto">
                <Message>{error}</Message>
              </Col>
            </Row>
          ) : (
            (filteredUsers.length > 0
              ? filteredUsers
                  .slice()
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              : hotels
                  .slice()
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            ).map((hotel, index) => (
              <>
                <Row className="align-items-center">
                  <Col sm="auto" className="me-2 fw-bold">
                    {index + 1}.
                  </Col>
                  <Col sm={1}>
                    {new Date(hotel.createdAt).toLocaleDateString(
                      "en-US",
                      options
                    )}
                  </Col>
                  <Col sm={3} className="fw-bold">
                    {hotel.partnerId != "65b949bd84febb1899b71e0e" &&
                      user.isSuperAdmin && (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>{getDevPartner(hotel.partnerId)}</Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            icon={faHandshake}
                            className="me-2 fs-6 text-info hover-effect"
                          />
                        </OverlayTrigger>
                      )}
                    {hotel.companyName}
                  </Col>
                  <Col sm={2}>{hotel.name}</Col>
                  <Col sm={3} className="text-success">
                    {hotel.location}
                  </Col>
                  <Col xs={2} className="ms-auto">
                    <Button variant="outline-primary" className="m-2 py-1 px-2">
                      <FontAwesomeIcon
                        onClick={() => {
                          dispatch({ type: HOTEL_UPDATE_RESET });
                          setEditModal(true);
                          setHotelToEdit(hotel);
                          setSendEmail(false);
                        }}
                        icon={faPenToSquare}
                      />
                    </Button>
                    <Button
                      variant="outline-primary"
                      className="m-2 py-1 px-2"
                      onClick={() => {
                        handleView(hotel);
                      }}
                    >
                      <FontAwesomeIcon
                        // onClick={() => {
                        //   setDeleteModal(true);
                        //   setUserToDelete(hotel._id);
                        //   setUserToDeleteName(hotel.companyName);
                        // }}
                        icon={faEye}
                      />
                    </Button>
                    <Button variant="outline-danger" className="m-2 py-1 px-2">
                      <FontAwesomeIcon
                        onClick={() => {
                          setDeleteModal(true);
                          setUserToDelete(hotel._id);
                          setUserToDeleteName(hotel.companyName);
                        }}
                        icon={faTrash}
                      />
                    </Button>
                  </Col>
                </Row>
                <hr className="my-1" />
              </>
            ))
          )}
        </Col>
      </Row>
    </>
  );
};

export default AdminDashboard;
