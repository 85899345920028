import React from "react";
import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import logo from "../images/tipmaidlogo.svg";
import { useNavigate } from "react-router-dom";
import "../landingpage.css";

const Header = () => {
  const navigate = useNavigate();
  const navigationHandler = () => {
    navigate("/login");
  };
  const navigationHandlerContact = () => {
    navigate("/contact");
  };
  return (
    <Navbar expand="lg" className="bg-light">
      <Container fluid className="px-md-5 px-2">
        <Row className="w-100">
          <Col>
            <Navbar.Brand href="/" className="ps-2">
              <img src={logo} width="150" alt="Tipmaid logo" />
            </Navbar.Brand>
          </Col>
          <Col xs="auto" className="ms-auto d-inline d-md-none">
            <Button
              size="sm"
              className="rounded-5 "
              onClick={navigationHandlerContact}
            >
              Book a Demo
            </Button>
          </Col>
          <Col xs="auto" className="ms-auto d-none d-md-inline">
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link
                  onClick={navigationHandler}
                  className="px-4 fw-medium"
                >
                  Login
                </Nav.Link>
                <Button
                  className="rounded-5 ms-2 px-3 py-0"
                  onClick={navigationHandlerContact}
                >
                  Book a Demo
                </Button>
              </Nav>
            </Navbar.Collapse>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
};

export default Header;
