import React, { useState } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../redux/Actions/userAction";
import Loader from "./Loader";
import Message from "./Message";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);

  const {
    loading,
    success,
    error: stateError,
  } = useSelector((state) => state.resetPassword);

  const dispatch = useDispatch();
  const navigation = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (email) {
      setErrorMessage(false);
      dispatch(resetPassword(email));
    } else {
      setErrorMessage(true);
    }
  };
  const handleExit = () => {
    navigation("/login");
    window.location.reload();
  };

  const styles = {
    shadow: {
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    },
  };
  return (
    <>
      <Row className="justify-content-center align-items-center vh-100 m-0 ">
        <Col
          md={4}
          className="pt-5 px-5 rounded-5 bg-white"
          style={styles.shadow}
        >
          {/* {stateError ? <Message variant="danger">{stateError}</Message> : null} */}
          <h2 className="text-primary text-center mb-0">Reset Password</h2>
          {loading ? <Loader /> : null}
          <Form className=" p-5 " onSubmit={handleSubmit}>
            {success ? (
              <>
                <Row className="justify-content-center align-items-center">
                  <Col className="text-success text-center" md="auto">
                    Your new password has been emailed!
                  </Col>
                </Row>
                <Row className="justify-content-center mt-3">
                  <Col xs="auto">
                    <Button
                      className="px-3 my-3"
                      variant="outline-success"
                      onClick={handleExit}
                    >
                      <FontAwesomeIcon
                        icon={faRightToBracket}
                        className="mx-2"
                      />
                      Back to login
                    </Button>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Enter email"
                  className="mb-3"
                >
                  <Form.Control
                    type="email"
                    placeholder="name@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errorMessage ? (
                    <span className="text-danger px-1 fs-7">
                      Please enter a valid email
                    </span>
                  ) : stateError ? (
                    <span className="text-danger px-1 fs-7">{stateError}</span>
                  ) : (
                    <span className="text-muted fst-italic px-1 fs-7">
                      Your new password will be sent on this email
                    </span>
                  )}
                </FloatingLabel>
                <Row className="justify-content-center">
                  <Col xs="auto">
                    <Button
                      className=" px-5"
                      variant="primary my-3"
                      type="submit"
                    >
                      Reset password
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ResetPassword;
