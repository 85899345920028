import React, { useEffect, useState } from "react";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  addTip,
  createValetTeam,
  payMaid,
} from "../redux/Actions/maidAction.js";
import Loader from "../components/Loader.jsx";
import Message from "../components/Message.jsx";
import Rating from "../components/Rating.jsx";
import Receipt from "../components/Receipt.jsx";
import { v4 as uuidv4 } from "uuid";

const PaymentTest = () => {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showRating, setShowRating] = useState(false);
  const [showAlert, setShowAlert] = useState(true);
  const [cardError, setCardError] = useState("");
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [idempotencyKey, setIdempotencyKey] = useState("");

  const paymentState = useSelector((state) => state.payMaid);
  const teamTipData = useSelector((state) => state.teamTipData);
  const { loading, success, error: stateError, transactionData } = paymentState;

  const queryParams = new URLSearchParams(location.search);
  const maid = queryParams.get("maid");
  const amount = queryParams.get("amount");
  const review = queryParams.get("review");
  const teamTip = queryParams.get("teamTip");
  const hotel = queryParams.get("hotel");
  console.log("Maid:", maid);
  console.log("Amount:", amount);
  console.log("Review:", review);
  console.log("TeamTip:", teamTip);
  console.log("Hotel:", hotel);
  let paymentIntentId;
  if (success) {
    paymentIntentId = transactionData.paymentIntentId;
  }
  const handlePayment = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    if (buttonDisabled) {
      console.log("Button disabled");
      return;
    }
    setButtonDisabled(true);
    try {
      const cardElement = elements.getElement(CardNumberElement);
      const { error: pmError, paymentMethod } =
        await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });
      if (pmError) {
        setCardError(pmError.message);
        setButtonDisabled(false);
        return;
      }
      const key = idempotencyKey || uuidv4();
      setIdempotencyKey(key);
      dispatch(
        payMaid({
          maid,
          hotel,
          token: paymentMethod.id,
          amount,
          teamTip,
          idempotencyKey: key,
        })
      );
    } catch (error) {
      console.error("Error handling payment request:", error.message);
      setCardError(error.message);
      setButtonDisabled(false);
    }
  };

  useEffect(() => {
    if (stripe) {
      console.log("Stripe is loaded");
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Total",
          amount: amount * 100,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment()
        .then((result) => {
          console.log("Result :", result);
          if (result) {
            if (result.applePay) {
              console.log("Apple Pay is available on this device and browser.");
            }
            if (result.googlePay) {
              console.log(
                "Google Pay is available on this device and browser."
              );
            }
            setPaymentRequest(pr);
          } else {
            console.log(
              "Payment Request Button is not available on this browser or device."
            );
          }
        })
        .catch((error) => {
          console.error("Error checking Payment Request availability:", error);
        });
    }
  }, [stripe, amount, elements]);
  useEffect(() => {
    if (paymentRequest) {
      console.log("Paymemt request exists");
      paymentRequest.on("paymentmethod", async (event) => {
        const { paymentMethod } = event;
        try {
          const key = idempotencyKey || uuidv4();
          setIdempotencyKey(key);
          dispatch(
            payMaid({
              maid,
              hotel,
              token: paymentMethod.id,
              amount,
              teamTip,
              idempotencyKey: key,
            })
          );
          event.complete("success");
        } catch (error) {
          event.complete("fail");
          console.error("Payment failed:", error);
          setCardError("Payment failed");
        }
      });
    }
  }, [paymentRequest]);

  useEffect(() => {
    if (success) {
      console.log("Payment successful!");
      if (teamTip !== "true") {
        dispatch(addTip(maid, paymentIntentId, amount, review));
      }
      if (teamTip === "true") {
        dispatch(
          createValetTeam(
            teamTipData.hotelId,
            teamTipData.valetTeamLocation,
            teamTipData.startDate,
            teamTipData.endDate,
            teamTipData.tipAmount,
            teamTipData.review,
            teamTipData.teamMembers,
            paymentIntentId
          )
        );
      } else {
        const timeoutId = setTimeout(
          () => {
            setShowAlert(false);
            if (teamTip !== "true") {
              setShowRating(true);
            }
          },
          teamTip !== "true" ? 1000 : 10000
        );
        return () => clearTimeout(timeoutId);
      }
    }
    if (stateError) {
      console.error("State error:", stateError);
      setButtonDisabled(false);
    }
  }, [success, stateError]);

  return (
    <>
      {stateError ? <Message>{stateError}</Message> : <></>}
      {cardError && success != true ? <Message>{cardError}</Message> : <></>}
      {success ? (
        <>
          {success && showAlert && (
            <>
              <Alert variant="success" className="text-center">
                <FontAwesomeIcon className="px-3" icon={faCheck} /> Payment
                successful
              </Alert>
              {teamTip == "true" && (
                <Receipt transactionData={transactionData} />
              )}
            </>
          )}
          {showRating && (
            <Rating maidAccount={maid} transactionData={transactionData} />
          )}
        </>
      ) : loading ? (
        <Loader />
      ) : (
        <Row>
          <Col className="p-2">
            <form
              onSubmit={handlePayment}
              className="border border-1 px-3 rounded-5 py-4"
            >
              <Row className="text-center px-3">
                <h3>Enter your cards details</h3>
                <hr />
              </Row>
              <Form.Group className="mb-2">
                <Form.Label>Card Number</Form.Label>
                <CardNumberElement className="form-control" />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>CVC Number</Form.Label>
                <CardCvcElement className="form-control" />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Expiry Date</Form.Label>
                <CardExpiryElement className="form-control" />
              </Form.Group>
              <div className="d-grid">
                <Button type="submit" variant="primary">
                  {loading ? "Processing..." : "Pay Now"}
                </Button>
              </div>
              {paymentRequest && (
                <Row className="my-2">
                  <Col>
                    <hr />
                  </Col>
                  <Col xs="auto" className="text-muted fs-7">
                    Or pay with
                  </Col>
                  <Col>
                    <hr />
                  </Col>
                </Row>
              )}
              {paymentRequest && (
                <Row>
                  <PaymentRequestButtonElement options={{ paymentRequest }} />
                </Row>
              )}
            </form>

            <Row className="sticky-bottom mt-3 justify-content-center">
              <Col xs="auto" className="text-center">
                <p style={{ fontSize: "12px" }}>
                  For any queries contact {""}
                  <a
                    href="mailto:support@tipmaid.io"
                    className="px-1 text-success"
                  >
                    support@tipmaid.io
                  </a>
                  . By tapping on "Pay" you agree with our{" "}
                  <a
                    className="px-1 text-primary"
                    href="https://tipmaid.io/tipmaidTerms"
                    target="blank"
                  >
                    Terms & Conditions
                  </a>
                  and{" "}
                  <a
                    href="https://tipmaid.io/privacyPolicy"
                    target="blank"
                    className="px-1 text-primary"
                  >
                    Privacy Policy
                  </a>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default PaymentTest;
