import {
  SET_REPORT_DOWNLOAD_DATA,
  SET_SELECTIVE_MAID_DATA,
} from "../Actions/reportsDownloadAction";

const initialState = {
  selectedMaid: null,
  startDate: "",
  endDate: "",
};

export const selecetiveMaidReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTIVE_MAID_DATA:
      const { selectedMaid, startDate, endDate } = action.payload;
      return {
        ...state,
        selectedMaid,
        startDate,
        endDate,
      };
    default:
      return state;
  }
};
export const reportListDownloadtReducer = (state = { maids: [] }, action) => {
  switch (action.type) {
    case SET_REPORT_DOWNLOAD_DATA:
      return {
        ...state,
        maids: action.payload,
      };
    default:
      return state;
  }
};
