import { faUser } from "@fortawesome/free-regular-svg-icons";
import {
  faCircle,
  faCircleCheck,
  faCircleUser,
  faCircleXmark,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
  Badge,
  Button,
  Col,
  Modal,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";

const ClientDetail = ({ staff, clients }) => {
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  const [show, setShow] = useState(false);
  const [members, setMembers] = useState([]);

  const handleClose = () => {
    setShow(false);
    setMembers([]);
  };
  const handleShow = () => setShow(true);
  const filterStaff = (clientId, service) => {
    return staff.filter(
      (member) =>
        member.hotel === clientId &&
        member.type === service &&
        member.chargesStatus === true
    );
  };
  const getStaff = (clientId, service) => {
    return staff.filter(
      (member) => member.hotel === clientId && member.type === service
    );
  };
  const getClientStatus = (client) => {
    if (
      !client.services.maids &&
      !client.services.valets &&
      !client.services.bellmen
    ) {
      return false;
    } else {
      return true;
    }
  };
  const styles = {
    shadow: {
      boxShadow: " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    },
  };
  return (
    <>
      {/* ----------------------------------------------- MODAL --------------------------------------------------- */}
      <Modal
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
        size="lg"
      >
        <Modal.Header className="bg-secondary">
          <Row className="w-100 align-items-center">
            <Col md="auto" className="ms-auto p-0 d-inline">
              <Button
                variant="outline-light p-0 px-1  m-0 border-0"
                onClick={handleClose}
              >
                <FontAwesomeIcon className="fs-4" icon={faXmark} />
              </Button>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Table hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>@</th>
                <th>Connect account</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {members &&
                members.map((member, index) => (
                  <tr key={index}>
                    <td className="fw-bold">{index + 1}.</td>
                    <td>{member.firstName + member.lastName}</td>
                    <td className="text-success">{member.serviceName}</td>
                    <td>{member.stripe_id}</td>
                    <td>
                      {member.chargesStatus ? (
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          className="text-success"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faCircleXmark}
                          className="text-danger"
                        />
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
      {/* ----------------------------------------------- LIST --------------------------------------------------- */}
      <Row className="p-4 m-0 my-3 ">
        <Col className="p-3 py-4 rounded-4" style={styles.shadow}>
          <Row>
            <Col sm={1} className="pe-0 fw-bold">
              #
            </Col>
            <Col sm={1} className="fw-bold">
              Date added
            </Col>
            <Col sm={2} className="fw-bold">
              Client name
            </Col>
            <Col sm={2} className="fw-bold">
              Contact person
            </Col>
            <Col sm={5} className="fw-bold text-center">
              Services
            </Col>
            <Col xs={1} className="text-center fw-bold ms-auto">
              Status
            </Col>
          </Row>
          <hr className="mt-3 mb-1" />
          {clients.map((client, index) => {
            return (
              <>
                <Row className="align-items-center">
                  <Col sm={1} className="pe-0 fw-bold">
                    {index + 1}.
                  </Col>
                  <Col sm={1}>
                    {new Date(client.createdAt).toLocaleDateString(
                      "en-US",
                      options
                    )}
                  </Col>
                  <Col sm={2} className="fw-bold text-primary">
                    {client.companyName}
                  </Col>
                  <Col sm={2} className="text-muted">
                    {client.name}
                  </Col>
                  <Col sm={5} className="">
                    <Row className="justify-content-center">
                      <Col xs={4}>
                        {client.services.maids ? (
                          <>
                            <Badge bg="success">Attendants</Badge>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip>
                                  <span>
                                    {filterStaff(client._id, "maid").length}
                                    <span> active out of </span>
                                    {getStaff(client._id, "maid").length}
                                  </span>
                                </Tooltip>
                              }
                            >
                              <FontAwesomeIcon
                                icon={faCircleUser}
                                className="mx-1 text-success hover-effect"
                                onClick={() => {
                                  setMembers(getStaff(client._id, "maid"));
                                  setShow(true);
                                }}
                              />
                            </OverlayTrigger>
                            {filterStaff(client._id, "maid").length}/
                            {getStaff(client._id, "maid").length}
                          </>
                        ) : (
                          <Badge bg="secondary">Attendants</Badge>
                        )}
                      </Col>
                      <Col xs={4}>
                        {client.services.valets ? (
                          <>
                            <Badge bg="success" className="px-3">
                              Valet
                            </Badge>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip>
                                  <span>
                                    {filterStaff(client._id, "valet").length}
                                    <span> active out of </span>
                                    {getStaff(client._id, "valet").length}
                                  </span>
                                </Tooltip>
                              }
                            >
                              <FontAwesomeIcon
                                icon={faCircleUser}
                                className="mx-1 text-success hover-effect"
                                onClick={() => {
                                  setMembers(getStaff(client._id, "valet"));
                                  setShow(true);
                                }}
                              />
                            </OverlayTrigger>
                            {filterStaff(client._id, "valet").length}/
                            {getStaff(client._id, "valet").length}
                          </>
                        ) : (
                          <Badge bg="secondary" className="px-3">
                            Valet
                          </Badge>
                        )}
                      </Col>
                      <Col xs={4}>
                        {client.services.bellmen ? (
                          <>
                            <Badge bg="success">Bellmen</Badge>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip>
                                  <span>
                                    {filterStaff(client._id, "bellman").length}
                                    <span> active out of </span>
                                    {getStaff(client._id, "bellman").length}
                                  </span>
                                </Tooltip>
                              }
                            >
                              <FontAwesomeIcon
                                icon={faCircleUser}
                                className="mx-1 text-success hover-effect"
                                onClick={() => {
                                  setMembers(getStaff(client._id, "bellman"));
                                  setShow(true);
                                }}
                              />
                            </OverlayTrigger>
                            {filterStaff(client._id, "bellman").length}/
                            {getStaff(client._id, "bellman").length}
                          </>
                        ) : (
                          <Badge bg="secondary">Bellmen</Badge>
                        )}
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={1} className="text-center ms-auto">
                    {getClientStatus(client) ? (
                      <span className="text-success">Active</span>
                    ) : (
                      <span className="text-warning">Pending</span>
                    )}
                  </Col>
                </Row>
                <hr className="my-1" />
              </>
            );
          })}
        </Col>
      </Row>
    </>
  );
};

export default ClientDetail;
