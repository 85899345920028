export const SET_SELECTIVE_MAID_DATA = "SET_SELECTIVE_MAID_DATA";
export const SET_REPORT_DOWNLOAD_DATA = "SET_REPORT_DOWNLOAD_DATA";

export const setSelectiveMaidData = (selectedMaid, startDate, endDate) => {
  return async (dispatch) => {
    dispatch({
      type: SET_SELECTIVE_MAID_DATA,
      payload: { selectedMaid, startDate, endDate },
    });
  };
};

export const setReportDownloadData = (maids) => {
  return async (dispatch) => {
    dispatch({
      type: SET_REPORT_DOWNLOAD_DATA,
      payload: maids,
    });
  };
};
