import React, { useState } from "react";
import {
  Alert,
  Button,
  Col,
  FloatingLabel,
  Form,
  Image,
  Row,
} from "react-bootstrap";
import { BACKEND_URL } from "../config";
import axios from "axios";
import logo from "../images/tipmaid-logo-tagline.png";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const ConatctForm = () => {
  const [validated, setValidated] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    hotelName: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    numberOfRooms: "",
    tipping: [],
    card: "",
  });

  const navigate = useNavigate();
  const navigationHandler = () => {
    navigate("/");
  };
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => {
      if (type === "checkbox") {
        const updatedArray = checked
          ? [...prevData[name], value]
          : prevData[name].filter((item) => item !== value);
        return {
          ...prevData,
          [name]: updatedArray,
        };
      } else if (type === "radio") {
        return {
          ...prevData,
          [name]: value,
        };
      } else {
        return {
          ...prevData,
          [name]: value,
        };
      }
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity()) {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const response = await axios.post(
        `${BACKEND_URL}api/users/sendEmail`,
        formData,
        config
      );
      setFormSubmitted(response.data.success);
    }
  };
  const styles = {
    shadow: {
      boxShadow: " rgba(149, 157, 165, 0.2) 0px 8px 24px",
    },
  };
  return (
    <>
      <Row className="m-0 justify-content-between vh-100 ">
        <Col md={6} className="p-4 mx-auto my-auto">
          {formSubmitted ? (
            <Alert className="text-center bg-transparent border border-success text-success rounded-4">
              <FontAwesomeIcon icon={faCheck} className="px-3" />
              We will get back to you soon !
            </Alert>
          ) : (
            <Form
              // ref={formRef}
              style={styles.shadow}
              className="p-5 rounded-5"
              noValidate
              validated={validated}
              // style={styles.boxShadow}
              onSubmit={submitHandler}
            >
              <FloatingLabel size="sm" label="Your Name" className="mb-3">
                <Form.Control
                  required
                  className="rounded-4"
                  type="text"
                  placeholder="Your Name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  This field is required.
                </Form.Control.Feedback>
              </FloatingLabel>
              <FloatingLabel size="sm" label="Email address" className="mb-3">
                <Form.Control
                  required
                  className="rounded-4"
                  type="email"
                  placeholder="example@email.com"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  This field is required.
                </Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel
                size="sm"
                label="Hotel/Resort Name"
                className="mb-3"
              >
                <Form.Control
                  required
                  className="rounded-4"
                  type="text"
                  placeholder="Hotel Name..."
                  name="hotelName"
                  value={formData.hotelName}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  This field is required.
                </Form.Control.Feedback>
              </FloatingLabel>
              <FloatingLabel size="sm" label="Address" className="mb-3">
                <Form.Control
                  required
                  className="rounded-4"
                  type="text"
                  placeholder="Address..."
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  This field is required.
                </Form.Control.Feedback>
              </FloatingLabel>

              <Row>
                <Col>
                  <FloatingLabel size="sm" label="City" className="mb-3">
                    <Form.Control
                      required
                      className="rounded-4"
                      type="text"
                      placeholder="City..."
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      This field is required.
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel size="sm" label="State" className="mb-3">
                    <Form.Control
                      required
                      className="rounded-4"
                      type="text"
                      placeholder="State..."
                      name="state"
                      value={formData.state}
                      onChange={handleInputChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      This field is required.
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel size="sm" label="Zip Code" className="mb-3">
                    <Form.Control
                      required
                      size="sm"
                      className="rounded-4"
                      type="number"
                      placeholder=""
                      name="zipCode"
                      value={formData.zipCode}
                      onChange={handleInputChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      This field is required.
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>
              <FloatingLabel size="sm" label="Number of Rooms" className="mb-3">
                <Form.Control
                  className="rounded-4"
                  type="number"
                  placeholder=""
                  name="numberOfRooms"
                  value={formData.numberOfRooms}
                  onChange={handleInputChange}
                />
              </FloatingLabel>
              <span className="fs-6 fw-bold">
                Please select your tipping experience
              </span>

              <Form.Check
                className="mt-2"
                type="checkbox"
                label="Room Attendant"
                value="Room Attendants"
                name="tipping"
                onChange={handleInputChange}
              />
              <Form.Check
                type="checkbox"
                label="Valet Attendant"
                value="Valet Attendants"
                name="tipping"
                onChange={handleInputChange}
              />
              <Form.Check
                type="checkbox"
                label="Bell Staff"
                value="Bell Staff"
                name="tipping"
                onChange={handleInputChange}
              />

              {/* <span className="fs-6 fw-bold">
                Please select your Appreciation Card
              </span> */}
              {/* <Form.Check
                className="mt-2"
                name="card"
                type="radio"
                label="Premium"
                value="Premium"
                onChange={handleInputChange}
              />
              <Form.Check
                type="radio"
                name="card"
                label="Basic"
                value="Basic"
                onChange={handleInputChange}
              /> */}
              <Row className="justify-content-center my-3">
                <Col xs="auto">
                  <Button className="px-5 rounded-5" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Col>
        <Col md={5} className="bg-primary">
          <Row className="justify-content-center align-items-center h-100">
            <Col md={8} className="p-5" onClick={navigationHandler}>
              <Image src={logo} fluid />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ConatctForm;
