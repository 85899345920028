import {
  faCheck,
  faDownload,
  faMagnifyingGlass,
  faRotateLeft,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import * as XLSX from "xlsx";
import { Col, FloatingLabel, Row, Table, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllPayments } from "../redux/Actions/paymentAction";

const TeamPayouts = ({ payments }) => {
  const [filteredPayments, setFilteredPayments] = useState();
  // const [payouts, setPayouts] = useState([]);
  const [filters, setFilters] = useState({
    date: "",
    transactionId: "",
    clientName: "",
    parentTransactionId: "",
  });
  // const { payments, loading } = useSelector((state) => state.allPayments);
  const options = { year: "numeric", month: "numeric", day: "numeric" };

  const handleFilterChange = (fieldName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [fieldName]: value,
    }));
  };
  const filterPayments = () => {
    const filteredResults = payments.filter((payment) => {
      const dateMatch =
        !filters.date ||
        format(new Date(payment.createdAt), "yyyy-MM-dd") ===
          format(new Date(filters.date), "yyyy-MM-dd");

      const transactionIdMatch =
        !filters.transactionId ||
        payment.paymentIntentId.includes(
          filters.transactionId.replace(/\s/g, "")
        );

      const parentTransactionIdMatch =
        !filters.parentTransactionId ||
        payment.parentTransactionId?.includes(
          filters.parentTransactionId.replace(/\s/g, "")
        );

      const clientNameMatch =
        !filters.clientName ||
        (payment.clientName &&
          payment.clientName
            .toLowerCase()
            .replace(/\s/g, "")
            .includes(filters.clientName.toLowerCase().replace(/\s/g, "")));
      return (
        dateMatch &&
        transactionIdMatch &&
        clientNameMatch &&
        parentTransactionIdMatch
      );
    });
    if (filteredResults.length > 0) {
      setFilteredPayments(filteredResults);
    } else {
      alert("No results found for filters applied");
    }
    // .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  };
  const clearFilters = () => {
    setFilters({
      date: "",
      transactionId: "",
      clientName: "",
      type: "",
      parentTransactionId: "",
    });
    setFilteredPayments(null);
  };
  const handleDownloadReports = () => {
    const table = document.querySelector(".team-payouts");
    const data = [];
    const headers = [];
    table.querySelectorAll("thead th").forEach((th) => {
      headers.push(th.textContent.trim());
    });
    data.push(headers);
    table.querySelectorAll("tbody tr").forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell, index) => {
        rowData.push(cell.textContent.trim());
      });
      data.push(rowData);
    });
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, "tipmaid-team-payouts.xlsx");
  };
  const dispatch = useDispatch();
  // useEffect(() => {
  //   if (payments) {
  //     console.log("All payments: ", payments);
  //     const filteredTeamTipPayments = payments.filter(
  //       (payment) => payment.teamTip
  //     );
  //     setPayouts(filteredTeamTipPayments);
  //   }
  //   // dispatch(getAllPayments());
  // }, []);
  const styles = {
    shadow: {
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    },
    shadow2: {
      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    },
  };
  return (
    <>
      {console.log("Payment array from team payouts: ", payments)}
      <Row className="m-0 mt-4">
        <Col className="p-0">
          {/* ----------------------------------------------- FILTERS -------------------------------------------- */}
          <Row className="mb-4 align-items-center justify-content-center">
            <Col xs={2}>
              <FloatingLabel label="Date">
                <Form.Control
                  type="date"
                  style={styles.shadow2}
                  className="rounded-4 border-0"
                  placeholder="Date"
                  value={filters.date}
                  onChange={(e) => handleFilterChange("date", e.target.value)}
                />
              </FloatingLabel>
            </Col>
            <Col xs={2}>
              <FloatingLabel label="Transfer Id">
                <Form.Control
                  type="text"
                  style={styles.shadow2}
                  className="rounded-4 border-0"
                  placeholder="Transaction Id"
                  value={filters.transactionId}
                  onChange={(e) =>
                    handleFilterChange("transactionId", e.target.value)
                  }
                />
              </FloatingLabel>
            </Col>
            <Col xs={2}>
              <FloatingLabel label="Parent Transaction Id">
                <Form.Control
                  type="text"
                  style={styles.shadow2}
                  className="rounded-4 border-0"
                  placeholder="Parent transaction Id"
                  value={filters.parentTransactionId}
                  onChange={(e) =>
                    handleFilterChange("parentTransactionId", e.target.value)
                  }
                />
              </FloatingLabel>
            </Col>
            <Col xs={2}>
              <FloatingLabel label="Client Name">
                <Form.Control
                  type="text"
                  style={styles.shadow2}
                  className="rounded-4 border-0"
                  placeholder="Client Name"
                  value={filters.clientName}
                  onChange={(e) =>
                    handleFilterChange("clientName", e.target.value)
                  }
                />
              </FloatingLabel>
            </Col>
            <Col xs="auto">
              <Button
                className="rounded-3"
                variant="secondary"
                onClick={clearFilters}
              >
                <FontAwesomeIcon icon={faRotateLeft} />
              </Button>
              <Button
                variant="success"
                className="mx-2 rounded-3"
                onClick={handleDownloadReports}
              >
                <FontAwesomeIcon icon={faDownload} />
              </Button>
              <Button
                className="rounded-3"
                variant="primary"
                onClick={() => filterPayments()}
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} className="me-1" />
              </Button>
            </Col>
          </Row>
          {/* -----------------------------------------  PAYOUT LIST -------------------------------------- */}
          <Row className="py-4 rounded-4" style={styles.shadow}>
            <Col>
              <Row className="fw-bold">
                <Col xs="auto">#</Col>
                <Col xs={1}>Date</Col>
                <Col md={2}>Sent To</Col>
                <Col md={3}>Transfer Id</Col>
                <Col md={3} className="p-0">
                  Parent Transaction
                </Col>
                <Col md={2}>Client Name</Col>
                <Col md="auto" className="p-0">
                  Tip
                </Col>
              </Row>
              <hr className="mb-3 mt-2" />
              {payments &&
                (filteredPayments && filteredPayments.length > 0
                  ? filteredPayments
                  : payments
                )
                  .slice()
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  .map((payment, index) => (
                    <>
                      <Row>
                        <Col xs="auto" className="fw-semibold">
                          {index + 1}.
                        </Col>
                        <Col md={1}>
                          {new Date(payment.createdAt).toLocaleDateString(
                            "en-US",
                            options
                          )}
                        </Col>
                        <Col md={2} className="text-info fst-italic">
                          {payment.connectAccount}
                        </Col>
                        <Col md={3}>{payment.paymentIntentId}</Col>
                        <Col md={3} className="text-muted fst-italic p-0">
                          {payment.parentTransactionId
                            ? payment.parentTransactionId
                            : "Not defined"}
                        </Col>
                        <Col md={2} className="fw-semibold">
                          {payment.clientName}
                        </Col>
                        <Col md="auto" className="p-0 text-info">
                          ${payment.amount}
                        </Col>
                      </Row>
                      <hr className="my-2" />
                    </>
                  ))}
            </Col>
          </Row>
          {/* --------------------------------------------- TABLE ------------------------------------------- */}
          <Table striped bordered hover className="team-payouts d-none">
            <thead>
              <tr>
                <th>#</th>
                <th>Date</th>
                <th>Sent To</th>
                <th>Transfer id</th>
                <th>Parent Transaction Id</th>
                <th>Client Name</th>
                <th>Tip</th>
              </tr>
            </thead>
            <tbody>
              {payments &&
                (filteredPayments && filteredPayments.length > 0
                  ? filteredPayments
                  : payments
                )
                  .slice()
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  .map((payment, index) => (
                    <tr key={index}>
                      <td className="fw-bold">{index + 1}</td>
                      <td>
                        {new Date(payment.createdAt).toLocaleDateString(
                          "en-US",
                          options
                        )}
                      </td>
                      <td className="text-muted fst-italic">
                        {payment.connectAccount}
                      </td>
                      <td className="text-muted fst-italic">
                        {payment.paymentIntentId}
                      </td>
                      <td className="text-muted fst-italic">
                        {payment.parentTransactionId
                          ? payment.parentTransactionId
                          : "Not defined"}
                      </td>
                      <td>{payment.clientName}</td>
                      <td>${payment.amount}</td>
                    </tr>
                  ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default TeamPayouts;
