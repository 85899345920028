import React, { useState } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
  faMagnifyingGlass,
  faRotateLeft,
} from "@fortawesome/free-solid-svg-icons";

const StaffDetail = ({ hotels, staff }) => {
  const [filters, setFilters] = useState({
    connectAccount: "",
    serviceName: "",
  });
  const [filteredStaff, setFilteredStaff] = useState(staff);
  const options = { year: "numeric", month: "numeric", day: "numeric" };

  const handleInputChange = (fieldName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [fieldName]: value,
    }));
  };
  const applyFilters = () => {
    const filtered = staff.filter(filterStaff);
    setFilteredStaff(filtered);
  };
  const filterStaff = (member) => {
    const { connectAccount, clientName, serviceName } = filters;
    return (
      (!connectAccount ||
        member.stripe_id
          .toLowerCase()
          .includes(connectAccount.toLowerCase())) &&
      (!serviceName ||
        member.serviceName.toLowerCase().includes(serviceName.toLowerCase()))
    );
  };
  const clearFilter = () => {
    setFilters({
      connectAccount: "",
      serviceName: "",
    });
    setFilteredStaff(staff);
  };
  const styles = {
    shadow: {
      boxShadow: " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    },
  };
  return (
    <>
      <Row className="mt-3 px-4 align-items-center justify-content-end">
        <Col xs={2} className="rounded-4">
          <FloatingLabel label="Connect account">
            <Form.Control
              style={styles.shadow}
              className="border-0 rounded-4"
              type="text"
              placeholder="Connect account"
              value={filters.connectAccount}
              onChange={(e) =>
                handleInputChange("connectAccount", e.target.value)
              }
            />
          </FloatingLabel>
        </Col>
        <Col xs={2} className="rounded-4">
          <FloatingLabel label="Service Name">
            <Form.Control
              style={styles.shadow}
              className="border-0 rounded-4"
              type="text"
              placeholder="Service Name"
              value={filters.serviceName}
              onChange={(e) => handleInputChange("serviceName", e.target.value)}
            />
          </FloatingLabel>
        </Col>
        <Col xs="auto" className="">
          <Button variant="secondary" className="me-2" onClick={clearFilter}>
            <FontAwesomeIcon icon={faRotateLeft} />
          </Button>
          <Button
            className="py-2 px-3"
            variant="success"
            onClick={applyFilters}
          >
            <FontAwesomeIcon icon={faMagnifyingGlass} className="me-1" />
            Apply Filter
          </Button>
        </Col>
      </Row>
      <Row className="p-4 m-0 my-3 ">
        <Col className="p-3 py-4 rounded-4" style={styles.shadow}>
          <Row>
            <Col sm="auto" className="me-2 fw-bold">
              #
            </Col>
            <Col sm={1} className="fw-bold">
              Date added
            </Col>
            <Col sm={2} className="fw-bold">
              Name
            </Col>
            <Col sm={1} className="fw-bold">
              @
            </Col>
            <Col sm={1} className="fw-bold">
              Type
            </Col>
            <Col sm={3} className="fw-bold">
              Client Name
            </Col>
            <Col xs={2} className="fw-bold">
              Connect account
            </Col>
            <Col xs={1} className="text-center fw-bold ms-auto">
              Status
            </Col>
          </Row>
          <hr className="mt-3 mb-1" />
          {filteredStaff.map((member, index) => {
            const hotel = hotels.find((hotel) => hotel._id === member.hotel);
            return (
              <>
                <Row className="align-items-center">
                  <Col sm="auto" className="me-2 fw-bold">
                    {index + 1}.
                  </Col>
                  <Col sm={1}>
                    {new Date(member.createdAt).toLocaleDateString(
                      "en-US",
                      options
                    )}
                  </Col>
                  <Col sm={2} className="fw-bold text-primary">
                    {member.firstName + " " + member.lastName}
                  </Col>
                  <Col sm={1} className="text-success">
                    {member.serviceName}
                  </Col>
                  <Col sm={1} className="text-muted">
                    {member.type}
                  </Col>
                  <Col sm={3} className="fw-bold text-primary">
                    {hotel ? hotel.companyName : "Unknown"}
                  </Col>
                  <Col sm={2} className="text-muted fst-italic">
                    {member.stripe_id}
                  </Col>
                  <Col xs={1} className="text-center ms-auto">
                    {member.chargesStatus ? (
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        className="text-success"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faCircleXmark}
                        className="text-danger"
                      />
                    )}
                  </Col>
                </Row>
                <hr className="my-1" />
              </>
            );
          })}
        </Col>
      </Row>
    </>
  );
};

export default StaffDetail;
