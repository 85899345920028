import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import NoResult from "../components/NoResult";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

const ViewStaff = ({ staff, type }) => {
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  const calculateMaidStats = (maid) => {
    const total = maid.tips.reduce((acc, tip) => acc + tip.amount, 0);
    const totalEarnings = Math.round(total);
    const totalReviews = maid.tips.reduce(
      (acc, tip) => acc + tip.review.length,
      0
    );
    return { totalEarnings, totalReviews };
  };

  return (
    <>
      {staff && staff.length > 0 ? (
        <Row className="my-4 p-2 border border-1 rounded-3">
          <Col>
            <Row className="fw-bold justify-content-between">
              <Col xs={1}>#</Col>
              <Col xs={2}>Date</Col>
              <Col xs={2}>Name</Col>
              <Col xs={2}>@</Col>
              <Col xs={2}>Connect Account</Col>
              <Col xs={1} className="text-center">
                Earnings
              </Col>
              <Col xs={2} className="text-center">
                Account Status
              </Col>
            </Row>
            <hr className="mt-1 mb-2" />
            {staff
              .slice()
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .map((member, index) => (
                <div key={index}>
                  <Row className=" justify-content-between">
                    <Col xs={1} className="fw-bold">
                      {index + 1}.
                    </Col>
                    <Col xs={2}>
                      {" "}
                      {new Date(member.createdAt).toLocaleDateString(
                        "en-US",
                        options
                      )}
                    </Col>
                    <Col xs={2}>{member.firstName + " " + member.lastName}</Col>
                    <Col xs={2} className="text-success">
                      {member.serviceName}
                    </Col>
                    <Col xs={2} className="text-muted fst-italic">
                      {member.stripe_id}
                    </Col>
                    <Col xs={1} className="text-center text-success">
                      ${calculateMaidStats(member).totalEarnings}
                    </Col>
                    <Col xs={2} className="text-center">
                      {member.chargesStatus ? (
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          className="text-success"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faCircleXmark}
                          className="text-danger"
                        />
                      )}
                    </Col>
                  </Row>
                  <hr className="my-1" />
                </div>
              ))}
          </Col>
        </Row>
      ) : (
        <NoResult />
      )}
    </>
  );
};

export default ViewStaff;
