import React from "react";
import { Col, Row } from "react-bootstrap";
import NoResult from "./NoResult";

const ViewUnverifiedTeam = ({ teamTips }) => {
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  console.log("TeamTips:", teamTips);
  return (
    <>
      {teamTips && teamTips.length > 0 ? (
        <Row className="my-4 p-2 border border-1 rounded-3">
          <Col>
            <Row className="fw-bold justify-content-between">
              <Col xs={1}>#</Col>
              <Col xs={1}>Date</Col>
              <Col xs={3}>Transaction ID</Col>
              <Col xs={2} className="p-0 text-center">
                Date Range
              </Col>
              <Col xs={1}>Location</Col>
              <Col xs={1} className="text-center">
                Tip
              </Col>
              <Col xs={3} className="text-center">
                Status
              </Col>
            </Row>
            <hr className="mt-1 mb-2" />
            {teamTips
              .filter((team) => !team.verified)
              .slice()
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .map((team, index) => (
                <div key={index}>
                  <Row className="justify-content-between">
                    <Col xs={1} className="fw-bold">
                      {index + 1}.
                    </Col>
                    <Col xs={1}>
                      {" "}
                      {new Date(team.createdAt).toLocaleDateString(
                        "en-US",
                        options
                      )}
                    </Col>
                    <Col xs={3} className="text-muted fst-italic">
                      {team.paymentIntentId ? team.paymentIntentId : "N/A"}
                    </Col>
                    <Col xs={2} className="p-0">
                      <span className="text-success mx-1">
                        {new Date(team.startDate).toLocaleDateString()}
                      </span>
                      to
                      <span className="text-success mx-1">
                        {new Date(team.endDate).toLocaleDateString()}
                      </span>
                    </Col>
                    <Col xs={1} className="text-muted">
                      {team.location ? team.location : "N/A"}
                    </Col>
                    <Col xs={1} className="text-center text-success">
                      ${team.tip}
                    </Col>
                    <Col xs={3} className="text-center text-warning">
                      Pending
                    </Col>
                  </Row>
                  <hr className="my-1" />
                </div>
              ))}
          </Col>
        </Row>
      ) : (
        <NoResult />
      )}
    </>
  );
};

export default ViewUnverifiedTeam;
