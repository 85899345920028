import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Row } from "react-bootstrap";

const NoResult = () => {
  return (
    <Row className="mt-5 pt-5 w-100 justify-content-center align-items-center">
      <Col xs="auto" className="text-center fw-semibold">
        <FontAwesomeIcon icon={faCircleExclamation} className="me-2" />
        No result found
      </Col>
    </Row>
  );
};

export default NoResult;
