import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import BackButton from "../components/BackButton";
import ViewStaff from "../components/ViewStaff";
import { setActiveTab } from "../redux/Actions/tabAcions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { getAllMaids, getValetTeams } from "../redux/Actions/maidAction";
import ViewVerifiedTeam from "../components/ViewVerifiedTeam";
import ViewUnverifiedTeam from "../components/ViewUnverifiedTeam";
import Loader from "../components/Loader";

const ViewClient = ({ client }) => {
  const [selectedTab, setSelectedTab] = useState("");
  const [staff, setStaff] = useState([]);
  const options = { year: "numeric", month: "numeric", day: "numeric" };

  let hotelId;
  if (client) {
    hotelId = client._id;
  }
  const {
    loading,
    error,
    maids: allMaids,
  } = useSelector((state) => state.maidsList);
  const { valetTeams } = useSelector((state) => state.valetTeams);

  const dispatch = useDispatch();
  const handleTabChange = (tab) => {
    if (loading) return;
    setSelectedTab(tab);
  };
  const handleBackButton = () => {
    setSelectedTab("");
    setStaff([]);
    dispatch(setActiveTab("#dashboard"));
  };

  useEffect(() => {
    dispatch(getAllMaids(hotelId));
    dispatch(getValetTeams(hotelId));
    setSelectedTab("");
    setStaff([]);
  }, [dispatch, hotelId]);

  useEffect(() => {
    if (allMaids) {
      switch (selectedTab) {
        case "attendants":
          setStaff(allMaids.filter((maid) => maid.type === "maid"));
          break;
        case "bellman":
          setStaff(allMaids.filter((maid) => maid.type === "bellman"));
          break;
        case "valet":
          setStaff(allMaids.filter((maid) => maid.type === "valet"));
          break;
        default:
          setStaff([]);
          break;
      }
    }
  }, [selectedTab]);

  useEffect(() => {
    if (!loading && allMaids) {
      setSelectedTab("attendants");
    }
  }, [loading, allMaids]);
  return (
    <>
      <Row className="m-0 p-5">
        <Col>
          <Row className="mb-4 text-muted">
            <Col className="hover-effect" xs="auto" onClick={handleBackButton}>
              <FontAwesomeIcon icon={faChevronLeft} className=" me-2" />
              <span className="fw-semibold">Dashboard</span>
            </Col>
          </Row>
          {client && (
            <Row className="mb-4">
              <h4 className="fw-bold text-center mb-3 text-primary">
                {client.companyName}
              </h4>
            </Row>
          )}
          <Row>
            <Col
              className={`p-2 mx-2 text-center fw-semibold ${
                selectedTab === "attendants"
                  ? "border-bottom border-4 border-success text-success"
                  : "border-bottom border-4 text-muted"
              }`}
              onClick={() => handleTabChange("attendants")}
              style={{ cursor: "pointer" }}
            >
              Attendants
            </Col>
            <Col
              className={`p-2 mx-2 text-center fw-semibold ${
                selectedTab === "bellman"
                  ? "border-bottom border-4 border-success text-success"
                  : "border-bottom border-4 text-muted"
              }`}
              onClick={() => handleTabChange("bellman")}
              style={{ cursor: "pointer" }}
            >
              Bellman
            </Col>
            <Col
              className={`p-2 mx-2 text-center fw-semibold ${
                selectedTab === "valet"
                  ? "border-bottom border-4 border-success text-success"
                  : "border-bottom border-4 text-muted"
              }`}
              onClick={() => handleTabChange("valet")}
              style={{ cursor: "pointer" }}
            >
              Valet
            </Col>
            <Col
              className={`p-2 mx-2 text-center fw-semibold ${
                selectedTab === "teamTipsVerified"
                  ? "border-bottom border-4 border-success text-success"
                  : "border-bottom border-4 text-muted"
              }`}
              onClick={() => handleTabChange("teamTipsVerified")}
              style={{ cursor: "pointer" }}
            >
              Team tips (verified)
            </Col>
            <Col
              className={`p-2 mx-2 text-center fw-semibold ${
                selectedTab === "teamTipsUnverified"
                  ? "border-bottom border-4 border-success text-success"
                  : "border-bottom border-4 text-muted"
              }`}
              onClick={() => handleTabChange("teamTipsUnverified")}
              style={{ cursor: "pointer" }}
            >
              Team tips (unverified)
            </Col>
          </Row>
          {loading ? (
            <Row className="mt-5">
              <Loader />
            </Row>
          ) : null}
          <Row className="mt-3">
            <Col>
              {selectedTab === "attendants" && (
                <ViewStaff staff={staff} type={"maid"} />
              )}
              {selectedTab === "bellman" && (
                <ViewStaff staff={staff} type={"bellmen"} />
              )}
              {selectedTab === "valet" && (
                <ViewStaff staff={staff} type={"valets"} />
              )}
              {selectedTab === "teamTipsVerified" && (
                <ViewVerifiedTeam teamTips={valetTeams && valetTeams} />
              )}
              {selectedTab === "teamTipsUnverified" && (
                <ViewUnverifiedTeam teamTips={valetTeams && valetTeams} />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ViewClient;
