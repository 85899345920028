import React, { useEffect } from "react";
import { Circle } from "rc-progress";
import CountUp from "react-countup";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingDollar } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllFailedPayments,
  getAllPayments,
} from "../redux/Actions/paymentAction";

const PaymentsCard = () => {
  const dispatch = useDispatch();
  const { payments } = useSelector((state) => state.allPayments);
  const { failedPayments } = useSelector((state) => state.failedPayments);

  useEffect(() => {
    dispatch(getAllPayments());
    dispatch(getAllFailedPayments());
  }, [dispatch]);

  const totalSuccessfulPaymentsEarning = payments
    .filter((payment) => payment.paymentStatus === "succeeded")
    .reduce((total, payment) => total + payment.amount, 0);

  // console.log("Successful payments", totalSuccessfulPaymentsEarning);
  const totalSuccessfulPayments = payments.filter(
    (payment) => payment.paymentStatus === "succeeded"
  ).length;

  const totalFailedPayments = failedPayments.length;

  const successfulPercentage =
    totalSuccessfulPayments > 0
      ? Math.round(
          (totalSuccessfulPayments /
            (totalSuccessfulPayments + totalFailedPayments)) *
            100
        )
      : 0;

  return (
    <>
      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <FontAwesomeIcon
            icon={faHandHoldingDollar}
            className="text-success fs-2 p-0 ps-2"
          />
        </Col>
        <Col xs="auto" className="round-progress">
          <Circle
            percent={
              (totalSuccessfulPayments /
                (totalSuccessfulPayments + totalFailedPayments)) *
              100
            }
            strokeWidth={10}
            trailWidth={8}
            strokeColor="#06BF9C"
          />
          <span className="ps-1 text-success">{successfulPercentage}%</span>
        </Col>
      </Row>
      <Row className="my-3">
        <Col>
          <Row className="mt-3">
            <h5 className="fw-bold text-primary m-0">Tips Processed</h5>
          </Row>
          <Row className="">
            <h4 className="fw-bolder text-info m-0">
              $
              <CountUp
                start={0}
                end={totalSuccessfulPaymentsEarning}
                delay={2}
              />
            </h4>
          </Row>
        </Col>
      </Row>

      <Row className="justify-content-between my-1">
        <Col xs="auto" className="text-muted">
          Successful
        </Col>
        <Col xs="auto" className="text-success">
          <CountUp start={0} end={totalSuccessfulPayments} delay={2} />
        </Col>
      </Row>
      <Row className="justify-content-between my-1">
        <Col xs="auto" className="text-muted">
          Unsuccessful
        </Col>
        <Col xs="auto" className="text-warning">
          <CountUp start={0} end={totalFailedPayments} delay={2} />
        </Col>
      </Row>
    </>
  );
};

export default PaymentsCard;
